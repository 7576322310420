import React, { useEffect, useState } from 'react'
import { dashboardTotalDataFetch } from '../../../actions/dashboard.action';
import { formatDate } from '../../../CommonUtils/commonUtils';
import StartCalendar from '../StartDateCalendar';
import { useDispatch, useSelector } from "react-redux";
import CalendarComponent from '../../../Components/calendar';
import UserIcon from "../../../Assets/Images/PAN.svg";
import LeadsIcon from "../../../Assets/Images/leads.svg";
import AssignmentIcon from "../../../Assets/Images/assign.svg";
import RewardPointsIcon from "../../../Assets/Images/RewardPointsIcon.svg";
import paymentIcon from "../../../Assets/Images/pay.svg";
import rupeesIcon from "../../../Assets/Images/Rupees.svg";
import "./index.css";
const DateFilter = () => {
    const previous3month = new Date().setMonth(3)
    const [startDate, setStartDate] = useState(formatDate(new Date(previous3month)))
    const [endDate, setEndDate] = useState(formatDate(new Date()))
    const dispatch = useDispatch();
    const totalData = useSelector((store) => store.dashboard.totalData);
    useEffect(() => {
        dispatch(dashboardTotalDataFetch(`?startDate=${startDate}&endDate=${endDate}`))
    }, [dispatch, startDate, endDate])
    return (
        <>
            <div style={{ display: 'flex', gap: 25, marginBottom: '15px' }}>

                <StartCalendar currentDate={previous3month} title={"Choose Start Date"} onDateChange={(date) => {
                    setStartDate(date)
                }} disableDate={{ maxDate: new Date() }} />
                <CalendarComponent title={"Choose End Date"} onDateChange={(date) => {
                    setEndDate(date);
                }} disableDate={{ maxDate: new Date() }} />
            </div>
            <TotalCountCards totalData={totalData} />
        </>
    )
}
const TotalCountCards = ({ totalData: { loading, data } }) => {
    return <div className="chartCard-container">
        <CountCard title={"Total Panelists"} icon={UserIcon} loading={loading} count={data?.totelPanelists} />
        <CountCard title={"Total Leads"} icon={LeadsIcon} loading={loading} count={data?.totelLeads} />
        <CountCard title={"Assignments Till  Date"} icon={AssignmentIcon} loading={loading} count={data?.totelAssignments} />
        <CountCard title={"Total Rewards"} icon={RewardPointsIcon} loading={loading} count={data?.totalRewardpoints} />
        <CountCard title={"Total Paid"} icon={paymentIcon} loading={loading} count={data?.totalPaid} symbol={rupeesIcon} />
    </div>
}
const CountCard = ({ count, icon, symbol, title, loading }) => {
    return <div className="chartCard">
        <div className="counter-icon">
            <img src={icon} alt={title + " icon"} style={{ width: "100%", height: "100%" }} />
        </div>
        {
            loading ?
                <p style={{ color: "#000", fontWeight: 600, fontSize: 28, lineHeight: "43px" }}>
                    Loading...
                </p>
                :
                <div className="chartCard-count">
                    {symbol && <img src={symbol} style={{ filter: "brightness(255) invert(1)" }} alt={"rupee symbol"} />}
                    {(count !== null && !isNaN(count)) ?
                        parseInt(count).toLocaleString()
                        :
                        <p style={{ fontSize: 30 }}>No Data</p>
                    }
                </div>
        }
        {title}
    </div >
}
export default DateFilter