import React, { useEffect } from "react";

import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import {
    activeRewardsFetch,
} from "../../actions/rewards.action";
import Tabel from "../../Components/Tabel";


import { Link, NavLink, useOutletContext, useParams } from 'react-router-dom'

import { dataCollectionDetailsReset, dataCollectionDetailsFetch } from "../../actions/dataCollectionDetails.action";

const Rewards = () => {
    const dataCollectionDetails = useSelector((state) => state.dataCollectionDetails);
    const { data } = dataCollectionDetails
    const dispatch = useDispatch();
    const { id } = useParams();
    useEffect(() => {



        let payload = {
            id: id
        };
        dispatch(dataCollectionDetailsFetch(payload));




    }, [
        id, dispatch,
    ]);

    const CountLabel = ({ count, placeholder }) => {
        return (
            <div>
                <div className="footerTextCount">{count === null ? "N/A" : count}</div>
                {placeholder && placeholder}
            </div>
        )
    }


    console.log('dataCollectionDetails', JSON.stringify(dataCollectionDetails))
    return (
        <>
            <div>

                <div>
                    <NavLink to={"/admin/dataCollectionInfo"} className="assignmentDetails-titleContainer"><u>Data Collection Info</u></NavLink>
                    {" > "}
                    Data Collection Details
                </div>

                <div className="profileCardFooterContainer">
                    {/* <CountLabel placeholder={"Assignments "} count={data.totalAssignmentCount} tooltip={`Total Assignment Completed`} /> */}
                    <CountLabel count={data?.successObject?.dataCollectionDate.split('T')[0]} placeholder={"Data Collection Date"} />
                    <CountLabel count={data?.successObject?.eclinicName} placeholder={"eClinic Name"} />

                    <CountLabel count={data?.successObject?.village} placeholder={"Village"} />
                    <CountLabel count={data?.successObject?.state} placeholder={"State"} />
                    <CountLabel count={data?.successObject?.district} placeholder={"District"} />
                </div>



                <Tabel style={{ margin: "30px 0" }}>
                    <thead>
                        <tr>

                            <th>Mobile Number</th>
                            <th>BSKY</th>
                            <th>Asthma</th>
                            <th>Cardiac disease</th>
                            <th>Diabetics</th>
                            <th>Cancer</th>
                            <th>Hypertension</th>
                            <th>Psychiatric disorder</th>
                            <th>Epilepsy/neurological disorders</th>
                            <th>Cataract</th>
                            <th>Suffering from any Chronic issues</th>
                            <th>Any other ailments</th>
                            {/* <th>Action</th> */}
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "#fff" }}>
                        {dataCollectionDetails?.loading ? (
                            <tr>
                                <td colSpan={12}>
                                    <h2>Loading...</h2>
                                </td>
                            </tr>
                        ) : data?.successObject?.dataCollectionFamily?.map((item) => (
                            <tr key={item.id}>

                                <td>{item?.mobileNumber}</td>
                                <td>{item?.bsky}</td>
                                <td>{item?.asthma}</td>
                                <td>{item?.cardiacDisease}</td>
                                <td>{item?.diabetics}</td>
                                <td>{item?.cancer}</td>
                                <td>{item?.hypertension}</td>
                                <td>{item?.psychiatricDisorder}</td>
                                <td>{item?.epilepsyNeurologicalDisorders}</td>
                                <td>{item?.cataract}</td>
                                <td>{item?.sufferingFromAnyChronicIssues}</td>
                                <td>{item?.anyOtherAilment}</td>

                            </tr>
                        ))
                        }

                    </tbody>
                </Tabel>
            </div>
            {/* action modal */}
        </>
    );
};

export default Rewards;
