import React, {
    useEffect,
    // useState
} from 'react'
import "./index.css";
// import UserIcon from "../../Assets/Images/PAN.svg";
// import LeadsIcon from "../../Assets/Images/leads.svg";
// import PatientIcon from "../../Assets/Images/patientIcon.svg";
// import AssignmentIcon from "../../Assets/Images/assign.svg";
// import paymentIcon from "../../Assets/Images/pay.svg";
import exclaim from "../../Assets/Images/exclaim.svg";
// import rupeesIcon from "../../Assets/Images/Rupees.svg";
// import LineChart from './LineChart';
import {
    // dashboardAssignmentsFetch, dashboardLeadsFetch,
    dashboardPanelistFetch,
    dashboardPaymentsFetch
} from '../../actions/dashboard.action';
import { useDispatch, useSelector } from 'react-redux';
// import CalendarComponent from '../../Components/calendar';
import MultiLineChart from './MultiLineChart';
import PaymentChart from './PaymentChart';
// import { dashboardTotalDataFetch } from '../../actions/dashboard.action';
// import StartCalendar from './StartDateCalendar';
// import { formatDate } from '../../CommonUtils/commonUtils';
import DateFilter from './DateFilter';
// const tabs = [
//     {
//         id: 1,
//         title: "Total Panelist",
//         icon: UserIcon
//     },
//     {
//         id: 2,
//         title: "Total Leads",
//         icon: LeadsIcon
//     },
//     {
//         id: 3,
//         title: "Total Converted Pateint",
//         icon: PatientIcon
//     },
//     {
//         id: 4,
//         title: "Total Assignments",
//         icon: AssignmentIcon
//     },


// ]
// const counterData = [
//     {
//         id: 1,
//         title: "Total Panelist",
//         count: "255",
//         icon: UserIcon,
//         backgroundColor: "#005D8E"
//     },
//     {
//         id: 2,
//         title: "Total Leads",
//         count: "353",
//         icon: LeadsIcon,
//         backgroundColor: "#00654D",
//     },
//     {
//         id: 3,
//         title: "Total Assignments",
//         count: "353",
//         icon: AssignmentIcon,
//         backgroundColor: "#3CBA84",
//     },
//     {
//         id: 4,
//         title: "Total Payment",
//         count: "353",
//         icon: paymentIcon,
//         backgroundColor: "#00B2DD",
//     }
// ]


// const totalDataCard = {
//     panelists: "649",
//     leads: "12,367",
//     assignments: "132",
//     totalPaid: "589",
// }
// const cardData = [
//     {
//         id: 1,
//         title: "Total Panelists",
//         count: "255",
//         icon: UserIcon,
//         backgroundColor: "#005D8E"
//     },
//     {
//         id: 2,
//         title: "Total Leads",
//         count: "353",
//         icon: LeadsIcon,
//         backgroundColor: "#00654D",
//     },
//     {
//         id: 3,
//         title: "Assignments Till  Date",
//         count: "353",
//         icon: AssignmentIcon,
//         backgroundColor: "#3CBA84",
//     },
//     {
//         id: 4,
//         title: "Total Paid",
//         count: "353",
//         icon: paymentIcon,
//         backgroundColor: "#00B2DD",
//         symbol: rupeesIcon
//     }
// ]

const DashboardCardData = {
    assignments: {
        previous: 112,
        active: 23,
        review: 611
    },
    panelistOnboard: {
        referred: 619,
        referredAccepted: "63%",
        directly: 112,
        review: 1235
    },
    leadToPatient: {
        registered: 9027,
        available: 3340,
        percent: 73
    },
    curebayContent: {
        created: 43,
        avgShared: 517,
        avgPublished: 2
    }
}
const Dashboard = () => {
    const dispatch = useDispatch();
    // const {
    //     panelistAdded,
    //     //     //  leadsAdded, assignmentsAdded, 
    //     paymentsTotal, } = useSelector((store) => store.dashboard);
    const panelistAdded = useSelector((store) => store.dashboard.panelistAdded);
    const paymentsTotal = useSelector((store) => store.dashboard.paymentsTotal);
    useEffect(() => {
        dispatch(dashboardPanelistFetch());
        // dispatch(dashboardLeadsFetch())
        // dispatch(dashboardAssignmentsFetch())
        dispatch(dashboardPaymentsFetch())


    }, [dispatch])

    // const previous3month = new Date().setMonth(3)
    // const [startDate, setStartDate] = useState(formatDate(new Date(previous3month)))
    // const [endDate, setEndDate] = useState(formatDate(new Date()))
    // useEffect(() => {
    //     dispatch(dashboardTotalDataFetch(`?startDate=${startDate}&endDate=${endDate}`))
    // }, [dispatch, startDate, endDate])
    return (
        <>
            <DateFilter />
            {/* <div style={{ display: 'flex', gap: 25, marginBottom: '15px' }}>

                <StartCalendar currentDate={previous3month} title={"Choose Start Date"} onDateChange={(date) => {
                    setStartDate(date)
                }} disableDate={{ maxDate: new Date() }} />
                <CalendarComponent title={"Choose End Date"} onDateChange={(date) => {
                    setEndDate(date);
                }} disableDate={{ maxDate: new Date() }} />
            </div> */}

            {/* 
            <div className="dashboard-title">Dashboard</div>
            <div className="dashboard-subtitle">See Panelist Activity Status</div>
            <div className="dashboard-container">
                {tabs.map(tab => (
                    <div
                        key={tab.id}
                        className={`tab-item ${isActive.id === tab.id ? "active" : ""}`}
                        onClick={() => setActive(tab)} >
                        <img src={tab.icon} className="tab-icon" />
                        {tab.title}
                    </div>
                ))}
            </div>
             */}
            {/* 
            <div className="counter-container">
                {counterData.map((countItem) => {
                    const { id, title, count,icon, ...styles } = countItem
                    return (
                        <div key={id} className="counter-block" style={styles}>
                            <img src={icon} className="counter-icon" alt={title + " icon"} /> {title}
                            <div className="counter-count">{count}</div>
                        </div>
                    )
                })}
            </div>
            */}

            {/* <TotalCountCards totalData={totalData} /> */}

            <div className='chart-container'>
                <div className='chartWrraper'>
                    <div className='chartWrraper-title'>Panelists &amp; Leads Addition</div>
                    <MultiLineChart chartData={panelistAdded} />
                </div>
                <div className='chartWrraper'>
                    <div className='chartWrraper-title'>Payments Made</div>
                    <PaymentChart title={"paymentsTotal"} chartData={paymentsTotal} />
                </div>
                {/* <LineChart
                    title={"Panelist Added"}
                    action={() => dispatch(dashboardPanelistFetch())}
                    chartData={panelistAdded}
                    getLabels={(data) => data.time}
                    getData={(data) => data.transaction_count}
                />

                <LineChart
                    title={"Leads Added"}
                    action={() => dispatch(dashboardLeadsFetch())}
                    chartData={leadsAdded}
                    getLabels={(data) => data.block}
                    getData={(data) => data.gain}
                /> */}
            </div>
            <div style={{ display: 'flex', marginTop: 30, gap: 30 }}>

                {/* Assignments */}
                <DashboardCard title={"Assignments"}>
                    <div className='chartInfobox-progress-container'>
                        <div className='chartInfobox-progress-bar'></div>
                    </div>
                    <div className='chartInfobox--countContainer'>
                        <div className='chartInfobox-count'>{DashboardCardData.assignments.previous}</div>
                        <ColorLabel color={"#9A4DE7"}>Previous Assignments</ColorLabel>
                        <div className='chartInfobox-count'>{DashboardCardData.assignments.active}</div>
                        <ColorLabel color={"#FFC531"} >Active Assignments</ColorLabel>
                    </div>
                    <div className='chartInfobox-reviewCount'>
                        <img src={exclaim} alt={"exclaimation icon"} /> {DashboardCardData.assignments.review} Responses to Review</div>
                </DashboardCard>

                {/* Panelist Onboard */}
                <DashboardCard title={"Panelist Onboard"} >
                    <div className='chartInfobox-progress-container' style={{ backgroundColor: '#4CE748' }}>
                        <div className='chartInfobox-progress-bar' style={{ backgroundColor: '#4570E0', width: '95%' }}></div>
                    </div>
                    <div className='chartInfobox--countContainer'>
                        <div className='chartInfobox-count'>{DashboardCardData.panelistOnboard.referred}</div>
                        <ColorLabel color={"#4570E0"}>Referred ({DashboardCardData.panelistOnboard.referredAccepted} accepted) </ColorLabel>
                        <div className='chartInfobox-count'>112</div>
                        <ColorLabel color={"#4CE748"} >Panelists recruited directly</ColorLabel>
                    </div>
                    <div className='chartInfobox-reviewCount'>
                        <img src={exclaim} alt='exclaimation icon' />
                        {DashboardCardData.panelistOnboard.review} Applications to Review</div>
                </DashboardCard>


                {/* LEAD TO PATIENT */}
                <LeadToPatient {...DashboardCardData.leadToPatient} />
                {/* Curebay Content */}
                <DashboardCard title={"Curebay Content"}>
                    <div className='chartInfobox--countContainer'>
                        <div className='chartInfobox-count' style={{ marginTop: 0 }}>{DashboardCardData.curebayContent.created}</div>
                        Created Contents
                        <div className='chartInfobox-count'>{DashboardCardData.curebayContent.avgShared}</div>
                        Avg. Times each content is shared
                        <div className='chartInfobox-count'>{DashboardCardData.curebayContent.avgPublished}</div>
                        Avg. contents published per week
                    </div>
                </DashboardCard>

            </div>

            {/*<div className='chart-container'>
                <LineChart
                    title={"Assignments Added"}
                    action={(time) => dispatch(dashboardAssignmentsFetch(time))}
                    chartData={assignmentsAdded}
                    getLabels={(data) => data.long_country}
                    getData={(data) => data.online_count}
                />
                <LineChart
                    title={"Total Payments"}
                    action={(time) => dispatch(dashboardPaymentsFetch(time))}
                    chartData={paymentsTotal}
                    getLabels={(data) => data.high}
                    getData={(data) => data.volume}
                />
            </div> */}
        </ >
    )
}
export const ColorLabel = ({ children, color, ...props }) => {
    return <>
        <span
            {...props}
            style={{ display: "inline-block", margin: "0 5px", backgroundColor: color, width: 10, height: 10, borderRadius: "50%" }}></span>
        {children}
    </>
}

const DashboardCard = ({ title, children }) => {
    return <div className='chartInfobox-container'>
        <div className='chartInfobox-header'>{title}</div>
        <div className='chartInfobox-body'>
            {children}
        </div>
    </div>
}
const LeadToPatient = ({ percent, registered, available }) => {

    return <DashboardCard title={"Lead to Patient"} >
        <ProgressBarCircle percent={percent} />
        <div className='chartInfobox--countContainer'>
            <div className='chartInfobox-count'>{registered}</div>
            Leads registered as Patients
            <div className='chartInfobox-count'>{available}</div>
            Leads yet to avail Curebay Services
        </div>
    </DashboardCard>
}
const ProgressBarCircle = ({ percent = 0 }) => {
    // debugger
    const rotate = (percent / 100) * 180 + 45;
    const transform = { transform: `rotate(${isNaN(rotate) ? 45 : rotate}deg)` }
    return <div className="chartInfo-progress-container" >
        <div className="chartIngoBox-barOverflowBack">
            <div className="chartInfobox-progressBarBack" style={{ ...transform }}></div>
        </div>
        <div className="chartIngoBox-barOverflow">
            <div className="chartInfobox-progressBar" style={{ ...transform }}></div>
            <span>{percent}%</span>
        </div>
    </div>
}

export default Dashboard