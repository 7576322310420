import React, { useEffect, useState } from 'react'
import { formatDate, getDataFromStore } from '../../../CommonUtils/commonUtils';
import CalendarComponent from '../../../Components/calendar';
import "./index.css"
import DropFiles from './DropFiles';
import { useDispatch, useSelector } from 'react-redux';
import { cureBayContentFetch, cureBayContentReset } from '../../../actions/cureBayContent.action';
import { NavLink } from 'react-router-dom';
// import StartDatePicker from './StartDatePicker';
// import EndDatePicker from './EndDatePicker';
const errorMsges = {
    description: "Description is required",
    files: "File is required",
    endDate: "End date should be greater than start date",
}
const initialState = { description: "", startDate: formatDate(new Date()), endDate: formatDate(new Date()), status: "0", files: null }
const CureBayContent = () => {
    const [cureBayContentDetails, setCureBayContentDetails] = useState(initialState)
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (cureBayContentDetails[name] !== value) {
            setCureBayContentDetails({ ...cureBayContentDetails, [name]: value })
        }
    }
    // const handleFileChange = (e) => {
    //     const { name, files } = e.target;
    //     if (files?.[0]) {
    //         setCureBayContentDetails({ ...cureBayContentDetails, [name]: URL.createObjectURL(files[0]) })
    //         URL.revokeObjectURL(cureBayContentDetails.img)
    //     }
    // }
    const handleFilesChange = (files) => {
        if (files?.[0]) {
            const newItem = Object.assign(files[0], { preview: URL.createObjectURL(files[0]) });
            setCureBayContentDetails((state) => ({ ...state, files: newItem }))
            // setCureBayContentDetails({ ...cureBayContentDetails, files: newItem })
        }

        // const newFiles = files.map((file) => Object.assign(file, { preview: URL.createObjectURL(files[0]) }))
        // setCureBayContentDetails({ ...cureBayContentDetails, files: newFiles })
    }

    // const fileInput = useRef(null)
    const handleClearFile = () => {
        URL.revokeObjectURL(cureBayContentDetails.files.preview)
        setCureBayContentDetails({ ...cureBayContentDetails, files: null })
        // fileInput.current.value = null;
    }
    const dispatch = useDispatch();
    const cureBayContentStore = useSelector((state) => state.cureBayContent)
    const [errorMsg, setErrorMsg] = useState({});
    const [endDateTouched, setEndDateTouched] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault()


        const requiredFields = Object.keys(errorMsges);
        const currentErrorMsg = { ...errorMsg };
        requiredFields.forEach((field) => {
            if (cureBayContentDetails[field] === "" || cureBayContentDetails[field] === null) {
                currentErrorMsg[field] = errorMsges[field];
            } else {
                delete currentErrorMsg[field];
            }
        })
        if (Date.parse(cureBayContentDetails["startDate"]) > Date.parse(cureBayContentDetails["endDate"])) {
            currentErrorMsg["endDate"] = errorMsges["endDate"];
        } else {
            delete currentErrorMsg["endDate"];
        }


        if (Object.keys(currentErrorMsg).length === 0) {
            const { description, files, status, startDate, endDate } = cureBayContentDetails;
            let formData = new FormData();    //formdata object
            formData.append('description', description);   //append the values with key, value pair
            formData.append('files', files);
            formData.append('active', status);
            formData.append('startDate', startDate);
            formData.append('endDate', endDate);
            try {
                const parsedUser = JSON.parse(getDataFromStore("userData"));
                const { user: { firstName, lastName } } = parsedUser;
                formData.append('modifiedBy', `${firstName} ${lastName}`);
            } catch (e) {
                formData.append('modifiedBy', "CureBay");
            }
            dispatch(cureBayContentFetch(formData))
            // setCureBayContentDetails(initialState)
        } else {
            setErrorMsg(currentErrorMsg);
        }
    }

    useEffect(() => {
        if (cureBayContentStore.status) {
            setTimeout(() => {
                dispatch(cureBayContentReset())
                setCureBayContentDetails(initialState);
                setErrorMsg({});
            }, 2000)
        }
    }, [cureBayContentStore.status, dispatch])
    const handleDateChangeError = (startDate, endDate) => {
        if (Date.parse(startDate) > Date.parse(endDate)) {
            const endDateErrorMsg = errorMsges["endDate"];
            if (endDateErrorMsg !== errorMsg["endDate"]) {
                setErrorMsg((errors) => ({ ...errors, endDate: endDateErrorMsg }));
            }
        } else {
            const newErrors = { ...errorMsg }
            delete newErrors["endDate"];
            setErrorMsg(newErrors);
        }
    }
    return (
        <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="cureBayContent-titleContainer">
                <NavLink to={"/admin/cureBayContent"} className="assignmentDetails-titleContainer">CureBay Content</NavLink>
                {" > "}
                Add New</div>
            {errorMsg["description"]?.length > 0 && <div className="addNew-errorMsg">{errorMsg["description"]}</div>}
            {errorMsg["files"] && <div className="addNew-errorMsg">{errorMsg["files"]}</div>}
            <div className='cureBayContent-descContainer'>
                <textarea
                    className='cureBayContent-textarea'
                    placeholder="Add description"
                    name='description'
                    value={cureBayContentDetails.description}
                    onChange={handleChange}
                    rows="2"
                />
                {/* <input className='cureBayContent-url' name='url' value={cureBayContentDetails.url} onChange={handleChange} type={"text"} placeholder="Paste URL link.." /> */}
                {/* <label htmlFor="uploadImage" className='curebay-imgInput'>
                    {cureBayContentDetails.img ?
                        <>
                            <img className='curebay-imgInputSelected' src={cureBayContentDetails.img} />
                        </>
                        :
                        <>
                            <img src={imagePlaceholder} className="curebay-imgInputIcon" />
                            <p>Browse to upload Image and Videos</p>
                        </>
                    }
                </label> */}
                <DropFiles onChange={handleFilesChange} files={cureBayContentDetails.files} />
                {cureBayContentDetails.files && <button className='clearImage-btn' onClick={handleClearFile}>Clear</button>}
                {/* {cureBayContentDetails.img && <img className='curebay-imgInputSelected' src={cureBayContentDetails.img} />} */}
                {/*<input type={"file"}
                    value={cureBayContentDetails.img.name}
                    name="img"
                    ref={fileInput}
                    onChange={handleFileChange}
                    id="uploadImage" style={{ display: "none" }} /> */}
            </div>
            <div className="cureBayContent-filterContainer">
                <div className='cureBayContent-statusBlock'>
                    <div className="cureBayContent-statusTitle">Choose Status for this content</div>
                    <div className='cureBayContent-statusContainer'>
                        <div>
                            <input type={"checkbox"} id="activeContentStatus" name="status"
                                value={cureBayContentDetails.status === "1" ? "0" : "1"}
                                checked={cureBayContentDetails.status === "1"} onChange={handleChange}
                            />
                            <label htmlFor="activeContentStatus">Active</label>
                        </div>
                    </div>
                </div>
                {errorMsg["endDate"] && <div className="addNew-errorMsg">{errorMsg["endDate"]}</div>}
                <div className="cureBayContent-calendalContainer">
                    <div className="cureBayContent-startDateContainer">
                        <CalendarComponent
                            title="Choose Start Date"
                            onDateChange={async (startDate) => {
                                if (cureBayContentDetails["startDate"] !== startDate) {
                                    setCureBayContentDetails((state) => ({ ...state, startDate }));
                                    if (endDateTouched) {
                                        handleDateChangeError(startDate, cureBayContentDetails["endDate"]);
                                    }
                                }
                            }
                            }
                            disableDate={{ minDate: new Date() }}
                        />
                        {/* <CalendarComponent 
                        title="Choose Start Date"
                            onDateChange={async (date) => {
                                if (cureBayContentDetails["startDate"] !== date) {
                                    setCureBayContentDetails({ ...cureBayContentDetails, startDate: date })
                                }
                            }}
                        /> */}
                    </div>
                    <div className="cureBayContent-endDateContainer">
                        <CalendarComponent
                            title="Choose End Date"
                            onDateChange={(endDate) => {
                                if (cureBayContentDetails["endDate"] !== endDate) {
                                    setCureBayContentDetails({ ...cureBayContentDetails, endDate })
                                    handleDateChangeError(cureBayContentDetails["startDate"], endDate);
                                }
                                if (!endDateTouched) {
                                    setEndDateTouched(true)
                                }
                            }}
                            disableDate={{ minDate: new Date() }}
                        />

                    </div>
                    <div className="cureBayContent-sendBtnContainer">
                        {cureBayContentStore.loading
                            ?
                            <div className='posting-msg'>posting...</div> :
                            <>
                                {cureBayContentStore.status ?
                                    <div className='submitted-msg'>Submitted</div> :
                                    <button type='submit' className='cureBayContent-sendBtn'>Send</button>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </form>
    )
}

export default CureBayContent;