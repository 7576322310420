import { getData, postDataStatus } from "../CommonUtils/api";

export const getSurgeryReferLeads = async () => {
    return await getData("getSurgeryLead");
}
// export const getSurgeryReferLeads = async () => {
//     return await getDataURL("../assets/mockapi/surgeryLead.json");
// }

export const postSurgeryReferLeads = async (param) => {
    const { id, status, leadSlab } = param;
    const url = `getApprovalSurgeryFormDashboard?leadId=${id}&status=${status}&slab=slab${leadSlab}`;
    return await postDataStatus(url);
}