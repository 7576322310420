import { Link } from "react-router-dom"
import Mail from "../../Assets/Images/Mail.svg";
import Phone from "../../Assets/Images/Phone.svg";
import ImagePlaceholder from "../../Components/ImagePlaceholder";

export const CountLabel = ({ count, placeholder, tooltip }) => {
    return (
        <div className="countLabel">
            <div className="footerTextCount">{count === null ? "N/A" : count}</div>
            {placeholder}
            {tooltip && <div className="tooltip">{tooltip}</div>}
        </div>
    )
}

export const ActivePanelistCard = ({ data }) => {
    return (<Link to={"" + data.id} className="activePanelist" style={{ textDecoration: "none", color: "unset" }}>

        <div className="activePanelistCardContainer">
            <ImagePlaceholder name={data.name} />
            {/* <div className="profileImagePlaceholder">
                <span>{data?.name?.charAt(0)}</span>
            </div> */}
            {/* <div className='profileImgContainer'><img src={ProfileIcon} className="ProfileIcon" alt="logo" /></div> */}
            <div className="detailsContainer">
                <p className="cardTitle">{data.name ? data.name : "N/A"}</p>
                <p className="referData">
                    {data.createdDate
                        ? "Joining Date: " + data.createdDate.substring(0, 10)
                        : "N/A"}
                </p>
                <div style={{ display: "flex", marginBottom: 5 }}>
                    <img src={Phone} className="phoneIcon" alt="logo" />
                    <span className="phoneNumber">
                        {data.number ? data.number : "N/A"}
                    </span>
                </div>
                <div style={{ display: "flex", marginBottom: 5 }}>
                    <img src={Mail} className="emailIcon" alt="logo" />
                    <span className="emailID">{data.email ? data.email : "N/A"}</span>
                </div>
            </div>
        </div>

        <div className="cardFooterContainer">
            <CountLabel placeholder={"Assignments "} count={data.totalAssignmentCount} tooltip={`Total Assignment Completed`} />
            <CountLabel placeholder={"Leads "} count={data.totalLeadCount} tooltip={"Total Leads Added"} />
            <CountLabel placeholder={"Reward "} count={data.totalRewardPoints} tooltip={"Reward Points Collected till date"} />
            <CountLabel placeholder={"Payment "} count={data.totalTransactionAmount} tooltip={"Total Payments"} />
        </div>
    </Link>)
}

export const RecentApplicantCard = ({ children, data }) => {
    return (<div className="panelistCard">
        <div className="cardContainer">
            {/* <div className='profileImgContainer'><img src={ProfileIcon} className="ProfileIcon" alt="logo" /></div> */}
            <ImagePlaceholder name={data.panelistName} />
            {/* <div className="profileImagePlaceholder">
                <span>{data.panelistName && data.panelistName.charAt(0)}</span>
            </div> */}
            <div className="detailsContainer">
                <p className="cardTitle">
                    {data.panelistName ? data.panelistName : "N/A"}
                </p>
                <p className="referData">
                    Referred on:{" "}
                    {data.refferalDate ? data.refferalDate.substring(0, 10) : "N/A"}
                    {/* {data.refferalDate ? new Date(data.refferalDate) : "N/A"} */}
                </p>
                <div style={{ display: "flex", marginBottom: 5 }}>
                    <img src={Phone} className="phoneIcon" alt="logo" />
                    <span className="phoneNumber">
                        {data.panelistPhoneNumber ? data.panelistPhoneNumber : "N/A"}
                    </span>
                </div>
                <div style={{ display: "flex", marginBottom: 5 }}>
                    <img src={Mail} className="emailIcon" alt="logo" />
                    <span className="emailID">{data.email ? data.email : "N/A"}</span>
                </div>
            </div>
        </div>
        <div style={{ display: "flex", padding: 25 }}>
            {children}
            {/* <p className="referText">
          Referred: <br />
          <b className="referName" style={{ textTransform: "capitalize" }}>{data.refferedPanelistName ? data.refferedPanelistName : "N/A"}</b>
        </p>
        <ApproveRejectBtn onClick={() => {
          this.onResponseClick(data, "Approved");
        }} variant={"approve"} icon={Tick} style={{ height: "fit-content", marginLeft: "auto" }} >
          Approve
        </ApproveRejectBtn>
        <ApproveRejectBtn onClick={() => {
          this.onResponseClick(data, "Rejected");
        }} variant={"reject"} icon={Cross} style={{ height: "fit-content", marginLeft: 15 }} >Reject</ApproveRejectBtn> */}
        </div>
    </div >)
}

