import React from 'react'
import "./index.css"
const buttonVariant = { approve: "approveBtn", reject: "rejectBtn" }

const ApproveRejectBtn = ({ variant, children, icon, ...props }) => {
    const type = buttonVariant[variant];
    return (
        <div className={`approveRejectBtn ${type ? type : ""}`} style={{ display: "flex" }} {...props}>
            {icon && <img src={icon} className={"approveRejectBtn-icon"} alt={"No icon found"} />}
            <span className={"approveRejectBtn-text " + type + "-text "} style={{ marginLeft: icon ? "10px" : "" }}>{children}</span>
        </div>
    )
}

export default ApproveRejectBtn