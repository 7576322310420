import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import './index.css';
import arr from "../../Assets/Images/arr.svg"

const sideBarOption = [
  {
    key: 6, value: 'Dashboard', nevLink: "/admin/dashboard"
  },
  {
    key: 1, value: 'Panelists', nevLink: "/admin/panelists"
  },
  {
    key: 2, value: 'Assignments', nevLink: "/admin/assignments"

  },

  {
    key: 4, value: 'CureBay Content', nevLink: "/admin/curebayContent"

  },

  // {
  //   key: 5, value: 'Potential Lead', nevLink: "/admin/potential-lead"

  // },
  {
    key: 3, value: 'Rewards', nevLink: "/admin/rewards"

  },
  // {
  //   key: 8, value: 'Promotion List', nevLink: "/admin/promotionList"

  // },
  {
    key: 9, value: 'Surgery Refer Leads', nevLink: "/admin/surgeryReferLeads"

  },

  {
    key: 7, value: 'Data Collection Info', nevLink: "/admin/dataCollectionInfo"

  },

  {
    key: 8, value: 'Payments', nevLink: "/admin/smpage",
    subMenu: [
      { key: '8-1', value: 'SM Payment', nevLink: "/admin/smpage" },
      // { key: '8-2', value: 'eClinic Payment', nevLink: "/admin/eclinicpage" },
      { key: '8-3', value: 'NMU Cashback', nevLink: "/admin/nmucashback" },
    ]
  },
  {
    key: 10, value: 'SM Agreements', nevLink: "/admin/smagreement"
  },
  {
    key: 11, value: 'SM Details', nevLink: "/admin/smdetails"
  },
 

]


export default function AppSidebar() {
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const handleSubMenuToggle = (key) => {
    setOpenSubMenu(openSubMenu === key ? null : key);
  };
  return (
    <div className='sidebarContainer'>
      {sideBarOption.map(({ nevLink, value, subMenu, key }) => {
        const isSubMenuOpen = openSubMenu === key;

        return (
          <div key={key} className="menu-item">
            <NavLink
              to={subMenu ? "#" : nevLink}
              className={({ isActive }) => isActive && !subMenu ? "nevBlock-active nevBlock" : "nevBlock"}
              onClick={() => subMenu && handleSubMenuToggle(key)}
            >
              {value} {key != 8 && <img src={arr} style={{ marginLeft: "auto" }} alt={"Goto icon"} />}
            </NavLink>
            {subMenu && isSubMenuOpen && (
              <div className="subMenu">
                {subMenu.map(sub => (
                  <NavLink
                    key={sub.key}
                    to={sub.nevLink}
                    className={({ isActive }) => isActive ? "nevBlock-active nevBlock" : "nevBlock"}
                  >
                    {sub.value} <img src={arr} style={{ marginLeft: "auto" }} alt={"Goto icon"} />
                  </NavLink>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
