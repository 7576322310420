import { call, put, takeEvery } from "redux-saga/effects";
import { activePanelistTypes } from "../types";
import { activePanelistSuccess, panelistError } from "../actions/panelist.action";
import { getActivePanelist } from "../services/api"

function* fetchActivePanelist() {
    try {
        const activePanelist = yield call(getActivePanelist);
        yield put(activePanelistSuccess(activePanelist.data));
    } catch (e) {
        yield put(panelistError(e));
    }
}

export default function* saga() {
    yield takeEvery(activePanelistTypes.FETCH, fetchActivePanelist);
}
