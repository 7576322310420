import { useState, React, useRef, useEffect } from "react";
import { Toast } from 'primereact/toast';
import { Column } from "primereact/column";
import { Dialog } from 'primereact/dialog';
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { urls } from "../../CommonUtils/config";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button } from "primereact/button";
import { getDataFromStore } from '../../CommonUtils/commonUtils';
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import moment from 'moment';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faCheck, faTimes, faPencilAlt, faRedo, faCopy } from "@fortawesome/free-solid-svg-icons";
import { OverlayPanel } from "primereact/overlaypanel";




function Index() {
    const toast = useRef(null);
    const [searchResults, setSearchResults] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);
    const [nameFilter, setNameFilter] = useState("");
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [modalVisible, setModalVisible] = useState(false);
    const [rejectComment, setRejectComment] = useState("");
    const [showCommentBox, setShowCommentBox] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [userid, setUserid] = useState();
    const [fromDate, setFromDate] = useState();
    const [toDate, setTodate] = useState();
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);
    const [loading, setLoading] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false);
    const [isDisabled, setIsDisabled] = useState({});
    const [visible, setVisible] = useState(false);
    const [upiId, setUpiId] = useState();
    const [comment, setComment] = useState("");
    const upiOverlayRef = useRef(null);
    const rejectOverlayRef = useRef(null);
    const [selectedComment, setSelectedComment] = useState("");
    const [flagVerification, setFlagverification] = useState(false);
    const [upiError, setUpiError] = useState({});
    const [isVerified, setIsVerified] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    const [previousUpiId, setPreviousUpiId] = useState("");
    const [patientIdFilter, setPatientIdFilter] = useState("");

    const truncateComment = (comment) => {
        if (!comment) return "N/A";
        return comment.length > 10 ? comment.substring(0, 10) + "..." : comment;
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(selectedComment);
        toast.current.show({ severity: 'success', summary: 'Copied', detail: 'Comment copied to clipboard', life: 2000 });
        setTimeout(() => {

            upiOverlayRef.current?.hide();
            rejectOverlayRef.current?.hide();
        }, 500);
    };
    console.log(selectedComment, "derfg")



    const handleActionChange = async (action, rowData) => {
        setSelectedAction(action);
        setSelectedRow(rowData);
        setModalVisible(true);
        setRejectComment("");
        setIsProcessing(true);

        setTimeout(() => {
            setIsProcessing(false);
        }, 2000);
    };



    const storedToken = localStorage.getItem("authToken");


    useEffect(() => {
        try {
            const parsedUser = JSON.parse(getDataFromStore("userData"));
            console.log(parsedUser?.user?.code, "rftgyhj")
            setUserid(parsedUser?.user?.code)
        } catch (e) {
            console.log(e, "gyhy")
        }
    }, [])

    const statusOptions = [
        { label: "Approved", value: "APPROVED" },
        { label: "Rejected", value: "REJECTED" },
        { label: "Initiated", value: "PAYMENTINITIATED" },
        { label: "Queued", value: "QUEUED" },
        { label: "Reversed", value: "REVERSED" },
        { label: "Processing", value: "PROCESSING" },
        { label: "Paid", value: "PAID" },
        { label: "Failed", value: "FAILED" },
    ];

    const actionOptions = [
        { label: "Approve", value: "APPROVED" },
        { label: "Reject", value: "REJECTED" }
    ];

    const fetchData = (search = false, fromDateValue = fromDate, toDateValue = toDate, statusValue = selectedStatus, patientIdValue = patientIdFilter, nameValue = nameFilter) => {
        setLoading(true);

        const formattedFromDate = fromDateValue ? moment(fromDateValue).format('YYYY-MM-DD') : null;
        const formattedToDate = toDateValue ? moment(toDateValue).format('YYYY-MM-DD') : null;
        let url = `${urls.commonbaseurl}paymentCashback/list-cashbackPayment?page=${search ? 1 : pageNumber}&size=${pageSize}`;

        if (formattedFromDate) url += `&fromDate=${formattedFromDate}`;
        if (formattedToDate) url += `&toDate=${formattedToDate}`;
        if (statusValue) url += `&status=${statusValue}`;
        if (patientIdValue) url += `&patientId=${patientIdValue}`;
        if (nameValue) url += `&name=${nameValue}`;

        axios.get(url, {
            headers: { Authorization: `Bearer ${storedToken}` }
        })
            .then((res) => {
                if (res.data?.data?.length > 0) {
                    setSearchResults(res.data.data);
                    setFilteredResults(res.data.data);
                    setTotalRecords(res.data.data[0]?.count || 0);
                } else {
                    setSearchResults([]);
                    setFilteredResults([]);
                    setTotalRecords(0);
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    console.error("No data found:", error.response.data.message);
                    toast.current.show({ severity: 'warn', summary: 'No Data', detail: 'No records found!' });
                    setSearchResults([]);
                    setFilteredResults([]);
                    setTotalRecords(0);
                } else {
                    console.error("Error fetching data:", error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch data!' });
                }
            })
            .finally(() => setLoading(false));
    };


    useEffect(() => {
        fetchData();
    }, [pageNumber, pageSize]);

    // Handle Frontend Name Filter
    // const handleNameSearch = (e) => {
    //     setNameFilter(e.target.value);
    //     if (e.target.value.trim() === "") {
    //         setFilteredResults(searchResults);
    //     } else {
    //         const filteredData = searchResults.filter((item) =>
    //             `${item.firstName} ${item.lastName}`
    //                 .toLowerCase()
    //                 .includes(e.target.value.toLowerCase())
    //         );
    //         setFilteredResults(filteredData);
    //     }
    // };


    const handleRejectDialog = (rowData) => {
        setSelectedRow(rowData);
        setRejectComment("");
        setModalVisible(true);
    };

    const handleApprove = async (e, rowData) => {
        if (!rowData) return;

        e.preventDefault();
        e.stopPropagation();

        // Prevent multiple clicks on the same row
        if (isDisabled[rowData.patientId]) return;

        setIsDisabled((prev) => ({ ...prev, [rowData.patientId]: true }));

        const url = `${urls.commonbaseurl}paymentCashback/update?patientId=${rowData.patientId}&userId=${userid}&orderId=${rowData?.orderId}&status=APPROVED&txnId=`;

        try {
            const response = await axios.put(url, {}, {
                headers: {
                    Authorization: `Bearer ${storedToken}`
                }
            });

            if (response.status === 200) {
                toast.current.show({ severity: 'success', summary: 'Approved', detail: `Payment cashback updated for Order: ${rowData.orderId}` });
                setModalVisible(false);
                fetchData();
            } else {
                throw new Error("Unexpected response");
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to update cashback' });
            console.error("Error updating payment cashback:", error);
        } finally {
            setTimeout(() => {
                setIsDisabled((prev) => ({ ...prev, [rowData.patientId]: false })); // Reset only this button
            }, 1000);
        }
    };

    const handleReject = async () => {
        if (!selectedRow) return;


        if (rejectComment.trim() === "") {
            toast.current.show({ severity: 'warn', summary: 'Comment Required', detail: 'Please enter a comment before rejecting.' });


            if (!showCommentBox) {
                setShowCommentBox(true);
            }
            return;
        }
        setIsDisabled((prev) => ({ ...prev, [selectedRow.patientId]: true }));

        const url = `${urls.commonbaseurl}paymentCashback/update-status?patientId=${selectedRow.patientId}&userId=${userid}&orderId=${selectedRow.orderId}&status=REJECTED&comment=${encodeURIComponent(rejectComment)}`;

        try {
            const response = await axios.put(url, {}, {
                headers: {
                    Authorization: `Bearer ${storedToken}`
                }
            });

            if (response.status === 200) {
                toast.current.show({ severity: 'error', summary: 'Rejected', detail: `Payment cashback rejected for Order: ${selectedRow.orderId}` });
                setModalVisible(false);
                setRejectComment("");
                setShowCommentBox(false);
                fetchData();
                // setFilteredResults((prev) =>
                //     prev.map((item) =>
                //         item.orderId === selectedRow.orderId ? { ...item, status: "REJECTED" } : item
                //     )
                // );


            } else {
                throw new Error("Unexpected response");

            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to update cashback status' });
            console.error("Error updating payment cashback status:", error);

        }
        finally {
            setIsDisabled((prev) => ({ ...prev, [selectedRow.patientId]: false }));
        }
    };

    const handleRetry = async (rowData) => {
        if (!rowData || isDisabled[rowData.patientId]) return;
        const url = `${urls.commonbaseurl}razorpay/send-cashback?patientId=${rowData.patientId}&amount=${rowData?.cashbackAmount}&userId=${userid}&orderId=${rowData?.orderId}&accountId=${rowData?.accountId}&sourceChannel=bo`;

        try {

            setIsDisabled((prev) => ({ ...prev, [rowData.patientId]: true }));

            const response = await axios.post(url, {}, {
                headers: {
                    Authorization: `Bearer ${storedToken}`
                }
            });

            console.log("API Response:", response);

            if (response.status === 400) {
                toast.current.show({
                    severity: "error",
                    summary: "Retry Failed",
                    detail: response.data?.message || "Something went wrong",
                });
            } else if (response.status === 200) {
                toast.current.show({
                    severity: "success",
                    summary: "Retry Successful",
                    detail: `Payment cashback processed for Order: ${rowData.orderId}`,
                });
                fetchData();
            } else {
                throw new Error("Unexpected response");
            }
        } catch (error) {
            console.error("Error updating payment cashback status:", error);


            let errorMessage = "Failed to update cashback status";
            if (error.response) {
                errorMessage = error.response.data?.message || "Unknown error occurred";
            }

            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: errorMessage,
            });
        } finally {

            setIsDisabled((prev) => ({ ...prev, [rowData.patientId]: false }));
        }
    };




    const handleFromDateChange = (e) => {
        const selectedFromDate = e.value;
        if (toDate && moment(selectedFromDate).isAfter(moment(toDate))) {
            toast.current.show({ severity: 'error', summary: 'Invalid Date', detail: 'From Date cannot be greater than To Date' });
            return;
        }
        setFromDate(selectedFromDate);
    };

    const handleToDateChange = (e) => {
        const selectedToDate = e.value;
        if (fromDate && moment(selectedToDate).isBefore(moment(fromDate))) {
            toast.current.show({ severity: 'error', summary: 'Invalid Date', detail: 'To Date cannot be earlier than From Date' });
            return;
        }
        setTodate(selectedToDate);
    };


    const clearFilters = () => {
        setNameFilter("");
        setPatientIdFilter("");
        setFromDate(null);
        setTodate(null);
        setSelectedStatus(null);
        setFilteredResults(searchResults);


        fetchData(false, null, null, null, "", "");
    };


    const [selectupirow, setSelectedupirow] = useState();

    const actionBodyTemplate = (rowData) => {
        console.log(rowData, "drfgvbh")
        return (
            <>

                <div style={{ display: "flex", gap: "10px" }}>
                    <span>
                        {rowData.vpa}{" "}

                    </span>
                    {(rowData?.status === "PAYMENTINITIATED" || rowData?.status === "FAILED" || rowData?.status === "FAILED") &&
                        <FontAwesomeIcon icon={faPencilAlt} style={{ cursor: "pointer" }} onClick={() => {
                            setUpiId(rowData.vpa);
                            setVisible(true);
                            setSelectedupirow(rowData);
                            setComment("");
                        }} />
                    }

                </div>

            </>
        );
    };

    const handleSave = async () => {
        if (!selectupirow) return;


        if (!isVerified) {
            setUpiError((prev) => ({
                ...prev,
                [selectupirow.orderId]: "Please verify your UPI ID before saving.",
            }));
            return;
        }




        if (comment.trim() === "") {
            toast.current.show({ severity: "warn", summary: "Comment Required", detail: "Please enter a comment before rejecting." });
            return;
        }

        const url = `${urls.commonbaseurl}patientPaymentInfo/update-vpa?patientId=${selectupirow.patientId}&userId=${userid}&orderId=${selectupirow?.orderId}&vpa=${upiId}&comment=${encodeURIComponent(comment)}`;


        try {


            const response = await axios.put(url, {}, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });

            if (response.status === 200) {
                toast.current.show({ severity: "success", detail: `Upi is updated for Order: ${selectupirow.orderId}` });

                // setFilteredResults((prev) =>
                //     prev.map((item) =>
                //         item.orderId === selectupirow.orderId ? { ...item, vpa: upiId, status: "PAYMENTINITIATED" } : item
                //     )
                // );
                fetchData();

                setVisible(false);
                setComment("");
                setFlagverification(false);
                setIsVerified(false);
                setUpiError((prev) => ({ ...prev, [selectupirow.orderId]: "" }));
                setPreviousUpiId("");
            } else {
                throw new Error("Unexpected response");
            }
        } catch (error) {
            toast.current.show({ severity: "error", summary: "Error", detail: "Failed to update cashback status" });
            console.error("Error updating payment cashback status:", error);
        }
    };

    const handleVerify = async () => {
        if (!selectupirow || isVerifying || upiId === previousUpiId) return;

        setIsVerifying(true);
        setUpiError((prev) => ({ ...prev, [selectupirow.orderId]: "" }));

        const url = `${urls.commonbaseurl}razorpay/verification-vpa?vpa=${upiId}&sourceChannel=bo&patientId=${selectupirow.patientId}&userId=${userid}`;

        try {
            const response = await axios.get(url, {
                headers: { Authorization: `Bearer ${storedToken}` },
            });

            if (response.status === 200 && response.data?.data) {
                setIsVerified(true);
                setFlagverification(true);
                setPreviousUpiId(upiId);
                toast.current.show({
                    severity: "success",
                    detail: `UPI of ${response.data.data} is verified successfully!`
                });
            } else {
                setUpiError((prev) => ({
                    ...prev,
                    [selectupirow.orderId]: "The entered UPI is incorrect. Please enter a valid UPI.",
                }));
            }
        } catch (error) {
            setUpiError((prev) => ({
                ...prev,
                [selectupirow.orderId]: "The entered UPI is incorrect. Please enter a valid UPI.",
            }));
            console.error("Error verifying UPI:", error);
        } finally {
            setIsVerifying(false);
        }
    };
    const handlePatientIdChange = (e) => setPatientIdFilter(e.target.value);
    const handleNameChange = (e) => setNameFilter(e.target.value);

    const handleSearchClick = () => {
        fetchData(true, fromDate, toDate, selectedStatus, patientIdFilter, nameFilter);
    };





    console.log(upiId, comment, "ftgh")



    return (
        <>
            <Toast ref={toast} />
            <div style={{ paddingTop: "20px", paddingBottom: "20px", display: "flex", flexDirection: "row", gap: "10px" }}>


                <div>
                    <InputText
                        value={patientIdFilter}
                        onChange={handlePatientIdChange}
                        placeholder="Search by Patient ID"
                    />
                </div>


                <div>
                    <InputText
                        value={nameFilter}
                        onChange={handleNameChange}
                        placeholder="Search by Name"
                    />
                </div>

                <div>
                    <Calendar
                        value={fromDate}
                        onChange={handleFromDateChange}
                        // onChange={(e) => setFromDate(e.value)}
                        placeholder="Select From Date"
                        dateFormat="dd-mm-yy"
                        showIcon
                    />
                </div>
                <div>
                    <Calendar
                        value={toDate}
                        onChange={handleToDateChange}
                        // onChange={(e) => setTodate(e.value)}
                        placeholder="Select To Date"
                        dateFormat="dd-mm-yy"
                        showIcon
                    />
                </div>

                <Dropdown
                    value={selectedStatus}
                    options={statusOptions}
                    onChange={(e) => setSelectedStatus(e.value)}
                    placeholder="Select Status"
                    style={{ width: "200px" }}
                />


                {/* Search Button */}
                {/* <div>
                    <button onClick={() => fetchData(true)} className="p-button p-component p-button-primary">Search</button>
                </div> */}

                <div>
                    <button onClick={handleSearchClick} className="p-button p-component p-button-primary">
                        Search
                    </button>
                </div>
                <Button label="Clear Filters" onClick={clearFilters} className="p-button-secondary" />
            </div>

            {loading ? (
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                    fontSize: "1.5rem",
                    fontWeight: "bold"
                }}>
                    <FontAwesomeIcon icon={faSpinner} spin size="2x" style={{ color: "#255b89" }} />
                </div>
            )
                : filteredResults.length === 0 ? (
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "60vh",
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        color: "#255b89"
                    }}>
                        No Data Found
                    </div>
                ) :
                    (
                        <DataTable
                            value={filteredResults}
                            paginator
                            rows={pageSize}
                            totalRecords={totalRecords}
                            lazy
                            first={(pageNumber - 1) * pageSize}
                            onPage={(e) => setPageNumber(e.page + 1)}
                            onRowsPerPageChange={(e) => setPageSize(e.value)}
                            loading={loading}


                        >
                            <Column
                                header="Patient Detail"
                                body={(rowData) => (
                                    <div style={{



                                    }}>
                                        <div style={{ fontWeight: "bold", color: "#255b89", fontSize: "14px" }}>
                                            {rowData.patient?.firstName || ""} {rowData.patient?.lastName || ""}
                                        </div>
                                        <div style={{ fontSize: "12px", color: "#555", marginTop: "4px" }}>
                                            <span style={{ fontWeight: "bold", color: "#333" }}>📞 </span> {rowData.patient?.mobile || "N/A"}
                                        </div>
                                        <div style={{ fontSize: "12px", color: "#555", marginTop: "4px" }}>
                                            <span style={{ fontWeight: "bold", color: "#333" }}>🆔 </span> {rowData.patient?.code || "N/A"}
                                        </div>
                                    </div>
                                )}
                            />


                            <Column header="Order ID/ Membership ID" field="orderId" />


                            <Column
                                header="Parent Code"
                                body={(rowData) => rowData.patient?.parentCode || "N/A"}
                            />

                            <Column header="Service Name" body={(rowData) => rowData.billing?.serviceName || "N/A"} />
                            <Column header="Center Name" body={(rowData) => rowData.billing?.cheCenterName || "N/A"} />
                            <Column header="GMV" body={(rowData) => rowData.billing?.totalAmount || "N/A"} />
                            <Column header="Cashback amount" field="cashbackAmount" />

                            <Column header="Payment Date" body={(rowData) =>
                                rowData?.billing?.paymentDateTime
                                    ? moment.utc(rowData?.billing?.paymentDateTime).local().format("DD-MM-YYYY HH:mm")
                                    : "N/A"
                            } />
                            <Column header="Status" body={(rowData) => (
                                <span style={{
                                    color: rowData.status === "PAID" ? "green" : rowData.status === "APPROVED" ? "orange" : rowData.status === "PAYMENTINITIATED" ? "black" : "red",
                                    fontWeight: "bold"
                                }}>
                                    {rowData.status === "PAYMENTINITIATED" ? "Initiated" : rowData.status}
                                </span>
                            )} />
                            <Column header="UPI"
                                // field="vpa"
                                body={actionBodyTemplate}
                            />
                            <Column header="UPI Comment"
                                body={(rowData) => (
                                    rowData.upiComment ?
                                        <span style={{ cursor: "pointer", textDecoration: "underline", color: "#255b89" }}
                                            onClick={(e) => {
                                                setSelectedComment(rowData.upiComment);
                                                upiOverlayRef.current.show(e);
                                            }}

                                        >
                                            {truncateComment(rowData.upiComment)}
                                        </span>
                                        : <span>N/A</span>
                                )}
                            />

                            <Column header="Reject Comment"
                                body={(rowData) => (
                                    rowData.rejectComment ?
                                        <span style={{ cursor: "pointer", textDecoration: "underline", color: "#255b89" }}
                                            onClick={(e) => {
                                                setSelectedComment(rowData.rejectComment);
                                                rejectOverlayRef.current.show(e);
                                            }}

                                        >
                                            {truncateComment(rowData.rejectComment)}
                                        </span> :
                                        <span>N/A</span>
                                )}
                            />


                            <Column header="Prescription - Lead" body={(rowData) => (
                                rowData.prescriptionDoc ? (
                                    <a href={`${urls.gcpUrl}${rowData.prescriptionDoc}`} target="_blank" rel="noopener noreferrer" style={{ color: '#255b89', textDecoration: 'underline', fontWeight: "bold" }}>
                                        View
                                    </a>
                                ) : (
                                    "N/A"
                                )
                            )} />
                            <Column header="Patient Photo" body={(rowData) => (
                                rowData.photoUpload ? (
                                    <a href={`${urls.gcpUrl}${rowData.photoUpload}`} target="_blank" rel="noopener noreferrer" style={{ color: '#255b89', textDecoration: 'underline', fontWeight: "bold" }}>
                                        View
                                    </a>
                                ) : (
                                    "N/A"
                                )
                            )} />

                            {/* Invoice URL */}
                            <Column header="Curebay Invoice" body={(rowData) => (
                                rowData?.billing?.invoiceUrl ? (
                                    <a href={rowData?.billing?.invoiceUrl} target="_blank" rel="noopener noreferrer" style={{ color: '#255b89', textDecoration: 'underline', fontWeight: "bold" }}>
                                        View
                                    </a>
                                ) : (
                                    "N/A"
                                )
                            )} />
                            <Column
                                header="Action"
                                body={(rowData) => (
                                    rowData?.status === "PAID" || rowData?.status === "APPROVED" || rowData?.status === "REJECTED" || rowData?.status === "PROCESSING" || rowData?.status === "QUEUED" ? (
                                        <span style={{ color: "#aaa", fontStyle: "italic" }}>No Action</span>
                                    )
                                        :
                                        rowData?.status === "FAILED" || rowData?.status === "REVERSED" ?
                                            <div style={{ display: "flex", gap: "15px" }} >
                                                <div
                                                    style={{
                                                        width: "40px",
                                                        height: "40px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        borderRadius: "50%",
                                                        backgroundColor: isDisabled[rowData.patientId] ? "#6c757d" : "#28a745", // Gray out if disabled
                                                        cursor: isDisabled[rowData.patientId] ? "not-allowed" : "pointer",
                                                        color: "white",
                                                        fontSize: "18px",
                                                        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                                                        transition: "background-color 0.3s",
                                                        opacity: isDisabled[rowData.patientId] ? 0.6 : 1,
                                                    }}
                                                    onClick={() => handleRetry(rowData)}
                                                    onMouseEnter={(e) => {
                                                        if (!isDisabled[rowData.patientId]) e.currentTarget.style.backgroundColor = "#218838";
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        if (!isDisabled[rowData.patientId]) e.currentTarget.style.backgroundColor = "#28a745";
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faRedo} />
                                                </div>


                                            </div>

                                            : (
                                                <div style={{ display: "flex", gap: "15px" }}>
                                                    <div
                                                        style={{
                                                            width: "40px",
                                                            height: "40px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            borderRadius: "50%",
                                                            backgroundColor: isDisabled[rowData.patientId] ? "#6c757d" : "#28a745", // Gray out if disabled
                                                            cursor: isDisabled[rowData.patientId] ? "not-allowed" : "pointer",
                                                            color: "white",
                                                            fontSize: "18px",
                                                            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                                                            transition: "background-color 0.3s",
                                                            opacity: isDisabled[rowData.patientId] ? 0.6 : 1, // Reduce opacity when disabled
                                                        }}
                                                        onClick={(e) => handleApprove(e, rowData)}
                                                        // onClick={() => handleActionChange("APPROVED", rowData)}
                                                        onMouseEnter={(e) => {
                                                            if (!isDisabled[rowData.patientId]) e.currentTarget.style.backgroundColor = "#218838";
                                                        }}
                                                        onMouseLeave={(e) => {
                                                            if (!isDisabled[rowData.patientId]) e.currentTarget.style.backgroundColor = "#28a745";
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faCheck} />
                                                    </div>

                                                    <div
                                                        style={{
                                                            width: "40px",
                                                            height: "40px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            borderRadius: "50%",
                                                            backgroundColor: "#dc3545",
                                                            cursor: "pointer",
                                                            color: "white",
                                                            fontSize: "18px",
                                                            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                                                            transition: "background-color 0.3s",
                                                        }}
                                                        // onClick={() => handleActionChange("REJECTED", rowData)}
                                                        onClick={() => handleRejectDialog(rowData)}
                                                        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#c82333")}
                                                        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#dc3545")}
                                                    >
                                                        <FontAwesomeIcon icon={faTimes} />
                                                    </div>
                                                </div>
                                            )

                                )}
                            />
                        </DataTable>

                    )}




            <Dialog
                visible={modalVisible}
                onHide={() => setModalVisible(false)}
                header="Enter reason for rejection"
                style={{ width: '30vw' }}
            >
                <textarea
                    value={rejectComment}
                    onChange={(e) => setRejectComment(e.target.value)}
                    placeholder="Enter reason"
                    style={{
                        width: "100%",
                        minHeight: "80px",
                        padding: "8px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                    }}
                />

                <div style={{ display: "flex", justifyContent: "center", gap: "10px", marginTop: "20px" }}>
                    <Button label="OK" className="p-button-success" onClick={handleReject} disabled={isDisabled[selectedRow?.patientId]} />
                    <Button label="Cancel" className="p-button-danger" onClick={() => setModalVisible(false)} />
                </div>
            </Dialog>
            <Dialog
                visible={visible}
                onHide={() => setVisible(false)}
                header="Edit UPI ID"
                modal
                style={{ width: "450px", borderRadius: "10px" }}
                footer={
                    <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                        <Button
                            label="Cancel"
                            onClick={() => setVisible(false)}
                            className="p-button-text p-button-sm"
                            style={{ backgroundColor: "#ddd", color: "#333", borderRadius: "8px" }}
                        />
                        <Button
                            label="Save"
                            onClick={handleSave}
                            className="p-button-sm"
                            style={{
                                backgroundColor: isVerified && upiId.trim() !== "" && comment.trim() !== "" ? "#007BFF" : "#ccc",
                                color: "white",
                                borderRadius: "8px",
                                cursor: isVerified && upiId.trim() !== "" && comment.trim() !== "" ? "pointer" : "not-allowed"
                            }}
                            disabled={!isVerified || upiId.trim() === "" || comment.trim() === ""}
                        />
                    </div>
                }
            >
                <div className="p-fluid">
                    {/* UPI ID Input */}
                    <div className="p-field" style={{ marginBottom: "15px", position: "relative", width: "100%" }}>
                        <label style={{ fontWeight: "bold", marginBottom: "5px", display: "block", fontSize: "14px", color: "#555" }}>
                            UPI ID
                        </label>
                        <div style={{ position: "relative", display: "flex", alignItems: "center", width: "100%" }}>
                            <InputText
                                value={upiId}
                                onChange={(e) => {
                                    setUpiId(e.target.value);
                                    setIsVerified(false); // Reset verification if UPI is changed
                                    setUpiError((prev) => ({ ...prev, [selectupirow?.orderId]: "" })); // Clear error on change
                                    setPreviousUpiId(""); // Reset previous UPI ID to allow new verification
                                }}
                                placeholder="Enter UPI ID"
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: upiError[selectupirow?.orderId] ? "1px solid red" : "1px solid #ccc",
                                    borderRadius: "8px",
                                    fontSize: "14px",
                                    transition: "border 0.2s ease-in-out",
                                    paddingRight: "70px"
                                }}
                                className="p-inputtext"
                            />
                            <button
                                className="p-button-primary"
                                style={{
                                    position: "absolute",
                                    right: "5px",
                                    height: "75%",
                                    fontSize: "12px",
                                    borderRadius: "6px",
                                    padding: "5px 12px",
                                    backgroundColor: isVerifying ? "gray" : "green",
                                    color: "white",
                                    cursor: isVerifying || upiId === previousUpiId || upiId === selectupirow?.vpa ? "not-allowed" : "pointer",
                                    borderBlockColor: "white"
                                }}
                                onClick={handleVerify}
                                disabled={isVerifying || upiId === previousUpiId || upiId === selectupirow?.vpa}
                            >
                                {isVerifying ? "Verifying..." : "Verify"}
                            </button>
                        </div>
                        {upiError[selectupirow?.orderId] && <small style={{ color: "red", fontSize: "12px" }}>{upiError[selectupirow.orderId]}</small>}
                    </div>

                    {/* Comment Textarea */}
                    <div className="p-field">
                        <label style={{ fontWeight: "bold", marginBottom: "5px", display: "block", fontSize: "14px", color: "#555" }}>
                            Comment
                        </label>
                        <textarea
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            placeholder="Enter reason"
                            style={{
                                width: "100%",
                                minHeight: "100px",
                                padding: "10px",
                                border: "1px solid #ccc",
                                borderRadius: "8px",
                                fontSize: "14px",
                                transition: "border 0.2s ease-in-out",
                                resize: "vertical",
                            }}
                            className="p-inputtext"
                        />
                    </div>
                </div>
            </Dialog>

            <OverlayPanel ref={upiOverlayRef} style={{ borderRadius: "10px", boxShadow: "0px 4px 10px rgba(0,0,0,0.2)", padding: "15px", maxWidth: "320px", background: "#fff" }}>
                <div style={containerStyle}>
                    <label style={labelStyle}>Comment</label>
                    <div style={commentBoxStyle}>
                        <p style={commentTextStyle}>{selectedComment}</p>
                        <button onClick={copyToClipboard} style={buttonStyle} title="Copy">
                            <FontAwesomeIcon icon={faCopy} /> Copy
                        </button>
                    </div>
                </div>
            </OverlayPanel>

            {/* Overlay Panel for Reject Comment */}
            <OverlayPanel ref={rejectOverlayRef} style={{ borderRadius: "10px", boxShadow: "0px 4px 10px rgba(0,0,0,0.2)", padding: "15px", maxWidth: "320px", background: "#fff" }}>
                <div style={containerStyle}>
                    <label style={labelStyle}>Comment</label>
                    <div style={commentBoxStyle}>
                        <p style={commentTextStyle}>{selectedComment}</p>
                        <button onClick={copyToClipboard} style={buttonStyle} title="Copy">
                            <FontAwesomeIcon icon={faCopy} /> Copy
                        </button>
                    </div>
                </div>
            </OverlayPanel>


        </>
    );
}

const containerStyle = {



    margin: "10px auto",
};

const labelStyle = {
    color: "#333",
    fontWeight: "600",
    fontSize: "14px",
    marginBottom: "8px",
    display: "block",
};

const commentBoxStyle = {

    padding: "10px",
    borderRadius: "6px",
    wordBreak: "break-word",
};

const commentTextStyle = {
    color: "#555",
    fontSize: "14px",
    marginBottom: "5px",
};

const buttonStyle = {
    background: "none",
    border: "none",
    color: "#007bff",
    cursor: "pointer",
    fontSize: "16px",
    transition: "color 0.3s",
    display: "block",
    marginTop: "5px",
    textAlign: "right",
    float: "right"
};
export default Index;
