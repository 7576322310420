import React, { Component } from "react";
import "./index.css";
import SearchIcon from "../../Assets/Images/Search.svg";
import Cross from "../../Assets/Images/Cross.svg";
import Tick from "../../Assets/Images/Tick.svg";
import Approved from "../../Assets/Images/Approved.svg";
import Reject from "../../Assets/Images/Reject.svg";
import CloseIcon2 from "../../Assets/Images/CloseIcon2.svg";
// import Assignment from "../../Assets/Images/Assignment.svg";
// import SellectedDropDown from "../../Assets/Images/SellectedDropDown.svg";
// import UnsellectedDropDown from "../../Assets/Images/UnsellectedDropDown.svg";
import DropDownComponent from "../../Components/DropDown/index";
import ApproveRejectBtn from "../../Components/ApproveRejectBtn";
import { recentpanelistFetch, activepanelistFetch, updatePanelistStatue, updatePanelistStatueReset } from "../../actions/panelist.action";
import { connect } from 'react-redux';


import { ActivePanelistCard, RecentApplicantCard } from "./panelistCards";

class PanelistPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecentApplicantActive: true,
      // recentApplicantsData: [],
      // activePanelistData: [],
      // isServerError: false,
      // isDropDownOpen: true,
      dropDownSellectedOption: null,
      searchQuery: ""
    };
  }

  componentDidMount() {
    this.props.getRecentPanelistData();
    this.props.getActivePanelistData();
  }

  // getRecentApplicantData(url) {
  //   getData(url).then((response) => {
  //     this.setState({ isServerError: false });
  //     if (response.status === 200) {
  //       this.setState({ recentApplicantsData: response.data.data });
  //       console.log(this.state.recentApplicantsData, "checking");
  //     } else {
  //       this.setState({ isServerError: true });
  //     }
  //   });
  // }

  // getActivePanelistData(url) {
  //   getData(url).then((response) => {
  //     this.setState({ isServerError: false });
  //     if (response.status === 200) {
  //       this.setState({ activePanelistData: response.data.data });
  //       console.log(this.state.activePanelistData);
  //     } else {
  //       this.setState({ isServerError: true });
  //     }
  //   });
  // }

  onTabChange(isRecentApplicant) {
    this.setState({
      isRecentApplicantActive: isRecentApplicant,
    });
  }


  onDropDownArrowClick = (optionValue) => {
    this.setState({ dropDownSellectedOption: optionValue });
  };

  onDropDownOptionClick = (option) => {
    this.setState({ dropDownSellectedOption: option });
  };

  onResponseClick = (data, status) => {
    console.log(data);
    let { id } = data;
    this.props.updatePanelistStatue({ id, status });
  }

  onPopupClose = () => {
    this.props.updatePanelistStatueReset();
    this.props.getRecentPanelistData();
  }
  handleSearchQuery = (e) => {
    this.setState({ searchQuery: e.target.value });
  }

  render() {
    const activePanelistArray = [];
    const activePanelistDataStore = this.props.activePanelistData.data
    for (const key in activePanelistDataStore) {
      activePanelistArray.push(activePanelistDataStore[key])
    }
    return (
      <div>
        <div className="titleContainer">Panelists</div>
        <div className="filterContainer">
          <div className="searchContainer">
            <img src={SearchIcon} className="searchIcon" alt="logo" />
            <input className="searchInput" placeholder="Search by Name" onChange={this.handleSearchQuery} />
          </div>
          <div className="panelist-dropdown">
            <DropDownComponent
              onArrowClick={this.onDropDownArrowClick}
              onOptionClick={this.onDropDownOptionClick}
              options={["Sort by Joining Date", "Sort by Alphabetically"]}
              placeholder="Sort by"
            />
          </div>
        </div>
        {this.props.updatePanelistStatusData ? <p>this.props.updatePanelistStatusData</p> : null}
        <div className="sellectionContainer">
          <div
            onClick={() => this.onTabChange(true)}
            style={{ borderRadius: "5px 0 0 5px" }}
            className={
              this.state.isRecentApplicantActive
                ? "activateButton"
                : "deactivateButton"
            }
          >
            {/* Recent Applicants */}
            Referred Panelists
          </div>
          <div
            onClick={() => this.onTabChange(false)}
            style={{ borderRadius: "0px 5px 5px 0" }}
            className={
              this.state.isRecentApplicantActive
                ? "deactivateButton"
                : "activateButton"
            }
          >
            Active Panelists
          </div>
        </div>

        {/* panelistdata cards */}
        {this.props.panelistData.loading
          ?
          <h1 style={{ textAlign: "center" }}>Recent applicants data Loading...</h1>
          :
          this.state.isRecentApplicantActive ?
            (this.props.recentPanelistData?.length > 0
              ?
              <div className="recentPanelistContainer">
                {this.props.recentPanelistData
                  .filter((panelist) => panelist.panelistName.toLowerCase().includes(this.state.searchQuery.toLowerCase()))
                  .map((data) => {
                    if (data.status === "Pending") {
                      return <RecentApplicantCard key={data.id} data={data}>
                        <p className="referText">
                          Referrer: <br />
                          <b className="referName" style={{ textTransform: "capitalize" }}>{data.refferedPanelistName ?
                            data.refferedPanelistName
                            : "N/A"}</b>
                        </p>
                        <ApproveRejectBtn onClick={() => {
                          this.onResponseClick(data, "Approved");
                        }} variant={"approve"} icon={Tick} style={{ height: "fit-content", marginLeft: "auto" }} >
                          Approve
                        </ApproveRejectBtn>
                        <ApproveRejectBtn onClick={() => {
                          this.onResponseClick(data, "Rejected");
                        }} variant={"reject"} icon={Cross} style={{ height: "fit-content", marginLeft: 15 }} >Reject</ApproveRejectBtn>
                      </RecentApplicantCard>
                    }
                    return null;
                  })}
              </div>
              :
              <h1 style={{ textAlign: "center" }}>No Recent Applicants Found</h1>
            )
            :
            this.props.activePanelistData.loading ?
              <h1 style={{ textAlign: "center" }}>Active panelist data Loading...</h1>
              :
              activePanelistArray?.length > 0
                ?
                <div className="activePanelistContainer">
                  {activePanelistArray.filter((panelist) => panelist.name.toLowerCase().includes(this.state.searchQuery.toLowerCase())).map((option) => {
                    return <ActivePanelistCard key={option.id} data={option} />
                  })}
                </div>
                :
                <h1 style={{ textAlign: "center" }}>No Active Panelists Found</h1>
        }

        {/* confirmation popup */}
        {this.props.updatePanelistStatusData && <div className="panelist-overlay">
          <div className="panelist-overlayContainer" onClick={() => { this.onPopupClose() }}>
          </div>
          <div className="panelist-statusUpdateBox">
            <div className="panelist-overlayUpdateStatusContainer">
              <div className="panelist-closeIconContainer">
                <img onClick={() => { this.onPopupClose() }} src={CloseIcon2} alt="Close Button" />
              </div>
              <div className="panelist-approveRejectIconContainer">
                {this.props.updatePanelistStatusData.status === 'Approved' ? <img src={Approved} alt="Approve" /> : <img src={Reject} alt="Reject" />}
              </div>
              <p className="panelist-overlayUpdateStatus">{this.props.updatePanelistStatusData.status}</p>
            </div>
          </div>
        </div>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    panelistData: state.panelistData,
    recentPanelistData: state.panelistData.recentPanelistData,
    activePanelistData: state.panelistData.activePanelistData,
    updatePanelistStatusData: state.panelistData.updatePanelistStatusData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRecentPanelistData: () => dispatch(recentpanelistFetch()),
    getActivePanelistData: () => dispatch(activepanelistFetch()),
    updatePanelistStatue: (id) => dispatch(updatePanelistStatue(id)),
    updatePanelistStatueReset: () => dispatch(updatePanelistStatueReset())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PanelistPage);
