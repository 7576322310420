
import React, { useState, useEffect } from 'react';

import "../../Components/ApproveRejectButton/ApproveModal.css";
import rejectpng from "../../Assets/Images/rejectpng.png";
import "../../Components/ApproveRejectButton/RejectModal.css";
import axios from 'axios';
import { urls } from "../../CommonUtils/config";
import {
    clearDataFromStore,
    getDataFromStore,
} from "../../CommonUtils/commonUtils";
import "../../CommonUtils/api";


const RejectModal = ({ onClose, requestid }) => {
    const storedToken = localStorage.getItem("authToken");

    const [validateStatus, setValidateStatus] = useState("R");
    const [validateBy, setValidateBy] = useState("");
    const [validateRemarks, setValidateRemarks] = useState("");
    const [fullName, setFullName] = useState(null);
    const [hasText, setHasText] = useState("");



    const performPutRequest = async (payload) => {
        try {
            // Log the payload data to the console
            console.log('Payload:', payload);

            const response = await axios.put(
                `${urls.engageBaseUrl}SMPayment/UpdatesSMPaymentRequestApproveRejectFromEngageBackOffice/${requestid}`,
                payload, {
                headers: {

                    'Authorization': `Bearer ${storedToken}`,

                    'Content-Type': 'application/json',

                }
            }
            );

            console.log('Response:', response.data);



        } catch (error) {
            console.error('Error:', error);
            alert('API request failed.'); // Show a generic error message
        }

    };


    useEffect(() => {
        try {
            const { user: { firstName, lastName } } = JSON.parse(getDataFromStore("userData"));
            setValidateBy(firstName + " " + lastName);
        } catch (e) {
            setValidateBy(null);
        }
    }, [])




    const handleInputChange = (event) => {
        const value = event.target.value;
        setValidateRemarks(value.slice(0, 200));
        setHasText(value.trim() !== ''); // Check if there is text in the input field
    };

    const handleSubmit = async () => {
        const payload = {
            validateStatus: validateStatus,
            validateBy: validateBy,
            validateRemarks: validateRemarks,
            requestid: requestid
        };

        // Perform the PUT request with the payload data
        await performPutRequest(payload);
        onClose(false); // Close the modal after the request is submitted
        window.location.reload();
    };
    return (
        <div className='modal-containerconfirm'>
            <div className="approve-modal">
                <div className="modal-content">
                    < img src={rejectpng} alt="Logo" className='imagapprove' />
                    <p className='status'>Status: Rejected</p>
                    <input type="text" className="placeholder" placeholder="Please enter remarks" value={validateRemarks}
                        onChange={handleInputChange} />

                    <button className={`buttonyes ${hasText ? 'visible' : 'invisible'}`} onClick={handleSubmit} disabled={validateRemarks.trim() === ''}>Submit</button>

                    <button className='button' onClick={() => onClose(false)}>Cancel</button>



                </div>

            </div>

        </div>
    );
};

export default RejectModal;
