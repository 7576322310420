import CryptoJS from "crypto-js";
import { urls } from "../CommonUtils/config";

export function encryptData(data) {
  const key = CryptoJS.enc.Utf8.parse(urls.secret_key);
  const iv = CryptoJS.enc.Utf8.parse(urls.init_vector);

  if (typeof data !== "string") {
    data = JSON.stringify(data);
  }
  const cipherText = CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return { data: cipherText.toString() };
}

export function decryptData(cipherText) {
  let secretkey = "aesEncryptionKey";
  let initVector = "encryptionIntVec";
  const key = CryptoJS.enc.Utf8.parse(secretkey);
  const iv = CryptoJS.enc.Utf8.parse(initVector);

  const bytes = CryptoJS.AES.decrypt(cipherText, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  let originalText = bytes.toString(CryptoJS.enc.Utf8);
  try {
    originalText = JSON.parse(originalText);
  } catch (error) {}
  return originalText;
}
