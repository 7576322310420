import { dashboardPanelistTypes, dashboardLeadsTypes, dashboardAssignmentsTypes, dashboardPaymentsTypes, dashboardTotalDataTypes } from "../types"
// DASHBOARD_PANELIST
export function dashboardPanelistFetch(val) {
    return {
        type: dashboardPanelistTypes.FETCH,
        payload: val,
    }
}
export function dashboardPanelistSuccess(val) {
    return {
        type: dashboardPanelistTypes.SUCCESS,
        payload: val
    }
}
export function dashboardPanelistError(val) {
    return {
        type: dashboardPanelistTypes.ERROR,
        payload: val
    }
}
export function dashboardPanelistReset() {
    return {
        type: dashboardPanelistTypes.RESET
    }
}

// DASHBOARD LEADS
export function dashboardLeadsFetch(val) {
    return {
        type: dashboardLeadsTypes.FETCH,
        payload: val,
    }
}
export function dashboardLeadsSuccess(val) {
    return {
        type: dashboardLeadsTypes.SUCCESS,
        payload: val
    }
}
export function dashboardLeadsError(val) {
    return {
        type: dashboardLeadsTypes.ERROR,
        payload: val
    }
}
export function dashboardLeadsReset() {
    return {
        type: dashboardLeadsTypes.RESET
    }
}

// DASHBOARD ASSIGNMENTS
export function dashboardAssignmentsFetch(val) {
    return {
        type: dashboardAssignmentsTypes.FETCH,
        payload: val,
    }
}
export function dashboardAssignmentsSuccess(val) {
    return {
        type: dashboardAssignmentsTypes.SUCCESS,
        payload: val
    }
}
export function dashboardAssignmentsError(val) {
    return {
        type: dashboardAssignmentsTypes.ERROR,
        payload: val
    }
}
export function dashboardAssignmentsReset() {
    return {
        type: dashboardAssignmentsTypes.RESET
    }
}

// DASHBOARD ASSIGNMENTS
export function dashboardPaymentsFetch(val) {
    return {
        type: dashboardPaymentsTypes.FETCH,
        payload: val,
    }
}
export function dashboardPaymentsSuccess(val) {
    return {
        type: dashboardPaymentsTypes.SUCCESS,
        payload: val
    }
}
export function dashboardPaymentsError(val) {
    return {
        type: dashboardPaymentsTypes.ERROR,
        payload: val
    }
}
export function dashboardPaymentsReset() {
    return {
        type: dashboardPaymentsTypes.RESET
    }
}

// DASHBOARD TOTAL DATA
export function dashboardTotalDataFetch(val) {
    return {
        type: dashboardTotalDataTypes.FETCH,
        payload: val,
    }
}
export function dashboardTotalDataSuccess(val) {
    return {
        type: dashboardTotalDataTypes.SUCCESS,
        payload: val
    }
}
export function dashboardTotalDataError(val) {
    return {
        type: dashboardTotalDataTypes.ERROR,
        payload: val
    }
}
export function dashboardTotalDataReset() {
    return {
        type: dashboardTotalDataTypes.RESET
    }
}