import React from 'react'
import { Link } from 'react-router-dom'
import "./index.css"
const PageNotFound = () => {
    return (
        <div className='notFound-container' style={{}}>
            <h1>Page Not Found</h1>
            <Link className='notFound-link' to={"/admin"}>Go back to home</Link>
        </div>
    )
}

export default PageNotFound