export const urls = {
    //stag url
    // engageBaseUrl: 'https://api-dev-engage.curebay.in/engage/',
    // curebayBaseUrl: 'https://api-stage.curebay.in/dhp/',
    // commonbaseurl:'https://common-stage.curebay.in/cs/',
    // tokenUrl: 'https://api-stage.curebay.in/dhp/gcp/oauth/token',
    // gcpUrl: 'https://storage.googleapis.com/curebay-nonprod-application-data/',
    // basicUsername: 'curebay',
    // basicPassword: 'cGFzc3dvcmQ=',
    // grant_type: 'password',
    // userName: 'curebayadmin@gmail.com',
    // password: 'QWRtaW5AMTIz',
    // secret_key: "8I96Y1FT9D12D6Y9",
    // init_vector: "encryptionIntVec"



    //prod url
    engageBaseUrl: 'https://engage.curebay.com/engage/',
    curebayBaseUrl: 'https://api.curebay.com/dhp/',
    commonbaseurl:'https://common.curebay.com/cs/',
    tokenUrl: 'https://api.curebay.com/dhp/gcp/oauth/token',
    gcpUrl: 'https://storage.googleapis.com/curebay-prod-application-data/',
    basicUsername: 'curebay',
    basicPassword: 'cGFzc3dvcmQ=',
    grant_type: 'password',
    userName: 'curebayadmin@gmail.com',
    password: 'QWRtaW5AMTIz',
    user_code: "SYSCBTPROD2024",
    password: "VzAxaEpUUDFKUEFPVGc0T1RFM04",
    secret_key: "3H7AY1KA9J52D3G8",
    init_vector: "encryptionIntVec"
};
// export const maxFileSize = 25000000;
export const curebayFileValidaton = {
    maxFileSize: 25,
    code: "size-too-large",
    message: `File size should be less than ${25} mb`
}