import { recentPanelistTypes, activePanelistTypes, updatePanelistStatusTypes, panelistDetailsTypes, referredPanelistsByID, leadsByPanelistID, moreLeadsByPanelistID } from "../types";
const initialState = {
    data: [],
    recentPanelistData: [],
    updatePanelistStatusData: null,
    activePanelistData: {
        data: [],
        loading: false,
        error: ""
    },
    panelistDetails: {
        data: null,
        loading: false,
        error: ""
    },
    referredPanelistsByID: {
        data: [],
        loading: false,
        error: ""
    },
    leadsByPanelistID: {
        data: [],
        loading: false,
        error: "",
        firstFetch: false,
        moreLoading: false,
        pageNumber: 1,
        hasMoreData: true
    },
    loading: false,
    error: ""
}
const reducer = (state = initialState, action) => {

    const { type, payload } = action;
    const { FETCH, SUCCESS, ERROR, RESET } = recentPanelistTypes;
    switch (type) {
        case FETCH:
            return { ...state, loading: true }


        // ACTIVE PANELIST
        case activePanelistTypes.FETCH:
            return {
                ...state, activePanelistData: { ...state.activePanelistData, loading: true }
            }
        case activePanelistTypes.SUCCESS:
            const newData = payload.reduce((acc, data) => ({ ...acc, [data.id]: data }), {})
            return {
                ...state, activePanelistData: { ...state.activePanelistData, data: newData, loading: false, }
            }
        case activePanelistTypes.ERROR:
            return { ...state, activePanelistTypes: { ...state.activePanelistData, loading: false, error: "" } }

        // panelistDetails cases-----------
        case panelistDetailsTypes.FETCH:
            return { ...state, panelistDetails: { ...state.panelistDetails, loading: true } }
        case panelistDetailsTypes.SUCCESS:
            return { ...state, panelistDetails: { ...state.panelistDetails, data: payload, loading: false } }
        case panelistDetailsTypes.ERROR:
            return { ...state, panelistDetails: { ...state.panelistDetails, loading: false, error: payload } }

        //REFERRED PANELIST BY ID
        case referredPanelistsByID.FETCH:
            return { ...state, referredPanelistsByID: { ...state.referredPanelistsByID, loading: true } }
        case referredPanelistsByID.SUCCESS:
            return { ...state, referredPanelistsByID: { ...state.referredPanelistsByID, data: payload, loading: false } }
        case referredPanelistsByID.ERROR:
            return { ...state, referredPanelistsByID: { ...state.referredPanelistsByID, loading: false, error: payload } }

        //LEADS BY PANELIST ID
        case leadsByPanelistID.FETCH:
            return { ...state, leadsByPanelistID: { ...state.leadsByPanelistID, loading: true } }
        case leadsByPanelistID.SUCCESS:
            return {
                ...state, leadsByPanelistID: {
                    ...state.leadsByPanelistID,
                    data: payload,
                    loading: false,
                    firstFetch: true,
                    pageNumber: state.leadsByPanelistID.pageNumber + 1
                }
            }
        case leadsByPanelistID.RESET:
            return {
                ...state,
                leadsByPanelistID: initialState.leadsByPanelistID
            }


        // MORE LEADS_BY_PANELIST_ID
        case moreLeadsByPanelistID.FETCH:
            return { ...state, leadsByPanelistID: { ...state.leadsByPanelistID, moreLoading: true } }
        case moreLeadsByPanelistID.SUCCESS:
            if (payload.length > 0) {
                return {
                    ...state, leadsByPanelistID: {
                        ...state.leadsByPanelistID,
                        data: [
                            ...state.leadsByPanelistID.data,
                            ...payload
                        ],
                        moreLoading: false,
                        pageNumber: state.leadsByPanelistID.pageNumber + 1
                    }
                }
            } else {
                return {
                    ...state, leadsByPanelistID: {
                        ...state.leadsByPanelistID,
                        moreLoading: false,
                        pageNumber: state.leadsByPanelistID.pageNumber + 1,
                        hasMoreData: false
                    }
                }
            }
        case moreLeadsByPanelistID.ERROR:
            return { ...state, leadsByPanelistID: { ...state.leadsByPanelistID, loading: false, error: payload } }

        // case moreLeadsByPanelistID.UPDATE_PAGE_NUMBER:
        //     return {
        //         ...state, leadsByPanelistID: {
        //             ...state.leadsByPanelistID,
        //             pageNumber: state.leadsByPanelistID.pageNumber + 1
        //         }
        //     }
        case moreLeadsByPanelistID.UPDATE_HAS_MORE:
            return {
                ...state, leadsByPanelistID: {
                    ...state.leadsByPanelistID,
                    hasMoreData: payload,
                    moreLoading: false
                }
            }



        case SUCCESS:
            return { ...state, recentPanelistData: payload, loading: false, error: "" }
        case updatePanelistStatusTypes.SUCCESS:
            return { ...state, updatePanelistStatusData: payload, loading: false }



        case updatePanelistStatusTypes.RESET:
            return { ...state, updatePanelistStatusData: payload }


        case updatePanelistStatusTypes.ERROR:
        case ERROR:
            return { ...state, loading: false, error: payload }


        case RESET:
            return initialState;
        default:
            return state;
    }
}

export default reducer;