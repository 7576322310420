import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { urls } from "../../CommonUtils/config";
import "../../CommonUtils/api";

const ConfirmPop = ({ onClose, requestId }) => {
  const storedToken = localStorage.getItem("authToken");

  console.log("TOKEN", storedToken);

  const onClickConfirm = async () => {
    try {
      console.log(requestId, "requestid");
      const response = await axios.put(
        `${urls.engageBaseUrl}SMRequestPaymentApproval/update?id=${requestId}`,
        {},
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );

      if (response.status === 200) {
        console.log(response.data.message);
        onClose();
      } else {
        console.error(
          "Error updating payment approval:",
          response.status,
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error while updating payment approval:", error);
    }
  };

  return (
    <div className="approve-modalconfirm">
      <div className="modal-containerconfirm">
        <div className="modal-contentconfirm">
          <p>Are you sure you want to charge 20% TDS ? </p>
          <div className="Detailsrow">
            <button className="button" onClick={() => onClickConfirm()}>
              Confirm
            </button>
            <button className="button" onClick={() => onClose()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPop;
