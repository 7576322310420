import React, { useState, useEffect } from "react";
import "./index.css";
import "./modal.css";

import UnCheckedRadioButton from "../../../Assets/Images/UnCheckedRadioButton.svg";
import CheckedRadioButton from "../../../Assets/Images/CheckedRadioButton.svg";
import LeftArrow from "../../../Assets/Images/LeftArrow.svg";
import RightArrow from "../../../Assets/Images/RightArrow.svg";
import CloseIcon from "../../../Assets/Images/closeIcon.svg";
import Tick from "../../../Assets/Images/Tick.svg";
import Cross from "../../../Assets/Images/Cross.svg";


import { NavLink, useParams } from "react-router-dom";

// import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApproveRejectBtn from "../../../Components/ApproveRejectBtn";
import { useDispatch, useSelector } from "react-redux";
import { assignmentDetailsFetch, assignmentDetailsNotReviewedFetch, assignmentDetailsReviewedFetch, updateAssignmentStatus } from "../../../actions/assignmentDetails.action";
import ImagePlaceholder from "../../../Components/ImagePlaceholder";

const CreateImagesAndVideoBlocks = ({ data, onSeeAllClick }) => {
  return (
    <div key={data.id}>
      <p className="assignmentDetails-bodyTitle2">
        {data.panelistName
          ? data.panelistName + "(" + data.panelistAssignmentStatus + ")"
          : "N/A"}
      </p>
      <p className="assignmentDetails-bodyTitleDetail">
        {data.panelistAssignmentDescription
          ? data.panelistAssignmentDescription
          : "N/A"}
      </p>
      {data?.mediaList ? (
        <div
          className="assignmentDetails-imageContainer"
        >
          {data.mediaList
            ? data.mediaList.map((option, index) => {
              if (index < 3) {
                if (option.mediaPublicUrl.slice(-3).includes('mp4')) {
                  return (
                    <video
                      key={option.id}
                      controls className="assignmentDetails-bodyImage" onClick={() => onSeeAllClick(data, index)}>
                      <source src={option.mediaPublicUrl} type="video/mp4" />
                      {/* <source src="movie.ogg" type="video/ogg" /> */}
                      Your browser does not support the video tag.
                    </video>
                  );
                } else {
                  return (
                    <img
                      key={option.id}
                      className="assignmentDetails-bodyImage"
                      src={option.mediaPublicUrl}
                      onClick={() => onSeeAllClick(data, index)}
                      alt="Assignment"
                    />
                  );
                }
              }
              return null
            })
            : null}

          {data.mediaList?.length > 3 ? (
            <div className="assignmentDetails-remainingImageContainer" onClick={() => onSeeAllClick(data, 3)}>
              <div className="assignmentDetails-remainingImageInnerContainer">
                <p className="assignmentDetails-imageCount">
                  +{data.mediaList.length - 3}
                </p>
                <p className="assignmentDetails-seeAll">See All</p>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div>Image not available</div>
      )}
    </div>
  );
};
const responseOptions = [
  { id: 1, value: "All", status: ["approved", "rejected", "submitted"] },
  { id: 2, value: "Reviewed", status: ["approved", "rejected"] },
  { id: 3, value: "Not Reviewed", status: ["submitted"] },
];

export default function AssignmentDetailsPage() {
  const { id: assignmentid } = useParams();

  const [responseType, setResponseType] = useState(responseOptions[0]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  // const [currentImageArray, setCurrentImageArray] = useState(null);
  const [currentPanelistData, setCurrentPanelistData] = useState(null);

  // const handleNextImage = () => {
  //   setCurrentImage((state) => state + 1);
  // };
  // const handlePreviousImage = () => {
  //   setCurrentImage((state) => state - 1);
  // };


  const assignmentDetailsStore = useSelector((state) => state.assignmentDetails);
  const assignmentDetails = assignmentDetailsStore.data;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(assignmentDetailsFetch(assignmentid))
  }, [dispatch, assignmentid]);




  const onSeeAllClick = async (data, index) => {
    // await setCurrentImageArray(data.mediaList);
    setCurrentPanelistData(data);
    setModalOpen(true);
    !isNaN(index) && setCurrentImage(index)
  };

  // const onResponseClick = (status) => {
  //   const { panelisId, panelistAssignmentId } = currentPanelistData;
  //   dispatch(updateAssignmentStatus({ panelisId, panelistAssignmentId, status }));
  // };
  const handleResponseOptionClick = (option) => {
    setResponseType(option);
    if (option.value === "Reviewed") {
      assignmentDetailsStore.reviewed.dataStatus === false &&
        dispatch(assignmentDetailsReviewedFetch({ assignmentId: assignmentid, status: option.status }))
    }
    if (option.value === "Not Reviewed") {
      assignmentDetailsStore.notReviewed.dataStatus === false && dispatch(assignmentDetailsNotReviewedFetch({ assignmentId: assignmentid, status: option.status }))
    }
  };

  if (assignmentDetailsStore.loading) {
    return <h1>Loading...</h1>;
  }

  return (

    <div >
      {assignmentDetails &&
        <>
          <div>
            <NavLink to={"/admin/assignments"} className="assignmentDetails-titleContainer">Assignments</NavLink>
            {" > "}
            {assignmentDetails.assignmentName}
          </div>


          <div className="assignmentDetails-body">
            <p className="assignmentDetails-bodyPanelistId">
              {assignmentDetails.assignmentName
                ? assignmentDetails.assignmentName
                : "N/A"}
            </p>
            <p className="assignmentDetails-bodyExpiryDate">
              Expiring on :{" "}
              {assignmentDetails.assignmentEndDate
                ? assignmentDetails.assignmentEndDate.substring(0, 10)
                : "N/A"}
            </p>
            <p className="assignmentDetails-bodySerrator"></p>
            <p className="assignmentDetails-bodyTitle1">Assignment Details:</p>
            <p className="assignmentDetails-bodyTitleDetail">
              {assignmentDetails.assignmentDescription
                ? assignmentDetails.assignmentDescription
                : "N/A"}
            </p>
            <p className="assignmentDetails-bodyTitle1">Responses</p>
            <div className="assignmentDetails-bodyFilterContainer">
              {responseOptions.map(option => (
                <div key={option.id}
                  className="assignmentDetails-bodyRadioContainer"
                  onClick={() => handleResponseOptionClick(option)}
                >
                  <img
                    className="assignmentDetails-bodyRadioIcon"
                    src={responseType.value === option.value ? CheckedRadioButton : UnCheckedRadioButton}
                    alt={option.value}
                    title={option.value}
                  />
                  <p className="assignmentDetails-bodyTitleDetail">
                    {option.value}
                  </p>
                </div>
              ))}
            </div>
            <div className="assignmentDetails-bodyImagesContainer">
              {responseType.value === "All" ?
                assignmentDetails?.panelists?.length === 0 ? <h2>No Assignments Found</h2> :
                  assignmentDetails.panelists?.map((panelist) => <CreateImagesAndVideoBlocks
                    key={panelist.panelisId}
                    data={panelist}
                    onSeeAllClick={onSeeAllClick} />)
                :
                responseType.value === "Reviewed" ?
                  assignmentDetailsStore?.reviewed?.loading
                    ? <h2 style={{ textAlign: "center" }}>Loading...</h2> :
                    assignmentDetailsStore?.reviewed?.data?.length === 0 ? <h2>No Reviewed Assignments Found</h2> :
                      assignmentDetailsStore.reviewed.data.map((panelist) => <CreateImagesAndVideoBlocks
                        key={panelist.panelisId}
                        data={panelist}
                        onSeeAllClick={onSeeAllClick} />)
                  : responseType.value === "Not Reviewed" &&
                    assignmentDetailsStore?.notReviewed?.loading
                    ? <h2>Loading...</h2> :
                    assignmentDetailsStore?.notReviewed?.data?.length === 0 ? <h2>No Unreviewed Assignments Found</h2> :
                      assignmentDetailsStore.notReviewed.data.map((panelist) => <CreateImagesAndVideoBlocks
                        key={panelist.panelisId}
                        data={panelist}
                        onSeeAllClick={onSeeAllClick} />)}
            </div>
          </div>
        </>
      }



      <AssignmentModal currentImage={currentImage} setCurrentImage={setCurrentImage} currentPanelistData={currentPanelistData} isModalOpen={isModalOpen} setModalOpen={setModalOpen} />

    </div >
  );
  //   }
}

const AssignmentModal = ({ isModalOpen, currentPanelistData, setModalOpen, currentImage, setCurrentImage }) => {
  const dispatch = useDispatch();
  // const [isModalOpen, setModalOpen] = useState(false);
  // const [currentImage, setCurrentImage] = useState(0);
  const handleNextImage = () => {
    setCurrentImage((state) => state + 1);
  };
  const handlePreviousImage = () => {
    setCurrentImage((state) => state - 1);
  };
  const onResponseClick = (status) => {
    const { panelisId, panelistAssignmentId } = currentPanelistData;
    dispatch(updateAssignmentStatus({ panelisId, panelistAssignmentId, status }))
  }
  return (
    isModalOpen && (
      <div
        className="assignment-modal-overlay"
        onClick={() => {
          setModalOpen(false);
        }}
      >
        <div
          className="assignment-modal-main"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="assignment-modal-body">
            <div className="assignment-modal-gallery">
              {currentImage !== 0 && (
                <div
                  className="assignment-modal-previous-btn"
                  onClick={handlePreviousImage}
                >
                  <img
                    className="assignment-modal-previous-btn-icon"
                    src={LeftArrow}
                    alt="Previous Slide"
                  />
                </div>
              )}

              {currentPanelistData.mediaList[currentImage]?.mediaPublicUrl.slice(-3).includes('mp4') ? <video controls className="assignment-modal-gallery-img" >
                <source src={currentPanelistData.mediaList[currentImage].mediaPublicUrl} type="video/mp4" />
                {/* <source src="movie.ogg" type="video/ogg" /> */}
                Your browser does not support the video tag.
              </video> : <img
                className="assignment-modal-gallery-img"
                src={
                  currentPanelistData.mediaList[currentImage].mediaPublicUrl
                }
                alt="Current Slide"
              />}

              {currentImage < currentPanelistData.mediaList.length - 1 && (
                <div className="assignment-modal-next-btn" onClick={handleNextImage}>
                  <img className="assignment-modal-next-btn-icon" src={RightArrow} alt="Next Slide"
                  />
                </div>
              )}
            </div>
            <div className="assignment-modal-profile">
              <div>
                <img
                  className="assignment-modal-close-btn"
                  onClick={() => setModalOpen(false)}
                  src={CloseIcon}
                  alt="Close modal"
                />
              </div>
              <div className="assignment-modal-profile-header">
                <div style={{ padding: 15, paddingLeft: 0 }}>
                  <ImagePlaceholder name={currentPanelistData.panelistName} style={{ width: 84, height: 84 }} />
                  {/* <img
                  className="assignment-modal-profile-image-icon"
                  src={
                    window.location.origin + "/assets/images/profile.jpg"
                  }
                /> */}
                </div>
                <div style={{ margin: "auto 0" }}>
                  <div className="assignment-modal-profile-title">
                    {currentPanelistData && currentPanelistData.panelistName
                      ? currentPanelistData.panelistName
                      : "N/A"}
                  </div>
                  <div className="assignment-modal-profile-time">
                    {currentPanelistData &&
                      currentPanelistData.completedDate
                      ? currentPanelistData.completedDate.substring(0, 10)
                      : "N/A"}
                  </div>
                </div>
              </div>
              <div className="assignment-modal-profile-body">
                <p>
                  {currentPanelistData &&
                    currentPanelistData.panelistAssignmentDescription
                    ? currentPanelistData.panelistAssignmentDescription
                    : "N/A"}
                </p>
                <div style={{ display: "flex", marginTop: "15px" }}>
                  <p>{currentPanelistData.currentPanelistData}</p>
                  {currentPanelistData.panelistAssignmentStatus === 'approved' ? <ApproveRejectBtn
                    variant={"approve"}
                    icon={Tick}
                    style={{ padding: "11px 21px", marginRight: 18 }}>
                    Approved
                  </ApproveRejectBtn> : currentPanelistData.panelistAssignmentStatus === 'rejected' ? <ApproveRejectBtn
                    variant={"reject"}
                    icon={Cross}
                    style={{ padding: "11px 21px" }}>
                    Rejected
                  </ApproveRejectBtn> : <>
                    <ApproveRejectBtn
                      variant={"approve"}
                      icon={Tick}
                      style={{ padding: "11px 21px", marginRight: 18 }}
                      onClick={() => {
                        onResponseClick("approved");
                      }}>
                      Approve
                    </ApproveRejectBtn>
                    <ApproveRejectBtn
                      variant={"reject"}
                      icon={Cross}
                      style={{ padding: "11px 21px" }}
                      onClick={() => {
                        onResponseClick("rejected");
                      }}>
                      Reject
                    </ApproveRejectBtn></>}


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )

}