export const apiTypeCreator = (type) => {
    return {
        FETCH: `${type}_FETCH`,
        SUCCESS: `${type}_SUCCESS`,
        ERROR: `${type}_ERROR`,
        RESET: `${type}_RESET`,
        UPDATE: `${type}_UPDATE`,
    };
};
export const countryList = [
    { name: 'Afghanistan', code: 'AF' },
    { name: 'Åland Islands', code: 'AX' },
    { name: 'Albania', code: 'AL' },
    { name: 'Algeria', code: 'DZ' },
    { name: 'American Samoa', code: 'AS' },
    { name: 'AndorrA', code: 'AD' },
    { name: 'Angola', code: 'AO' },
    { name: 'Anguilla', code: 'AI' },
    { name: 'Antarctica', code: 'AQ' },
    { name: 'Antigua and Barbuda', code: 'AG' },
    { name: 'India', code: 'IN' },
    { name: 'Cook Islands', code: 'CK' },
    { name: 'Costa Rica', code: 'CR' },
    { name: 'Cote D\'Ivoire', code: 'CI' },
    { name: 'Croatia', code: 'HR' },
    { name: 'Cuba', code: 'CU' },
    { name: 'Cyprus', code: 'CY' },
    { name: 'Czech Republic', code: 'CZ' },
    { name: 'Denmark', code: 'DK' },
    { name: 'Djibouti', code: 'DJ' },
    { name: 'Dominica', code: 'DM' },
    { name: 'Dominican Republic', code: 'DO' },
    { name: 'Ecuador', code: 'EC' },
    { name: 'Egypt', code: 'EG' }
]
export const getCountryCode = (countryName) => {
    return countryList.find((country) => country.name.toUpperCase() === countryName.toUpperCase());
}

export function nFormatter(num) {
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
    }
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return num;
}
export const getLast7daysName = () => {
    // const weekday = ['monday', 'tuesday', 'wednesday', 'thursday','friday', 'saturday', 'sunday'];
    let weekday = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

    var goBackDays = 7;

    var today = new Date();
    var daysSorted = [];

    // for (var i = goBackDays-1; i >= 0; i--) {
    //     // var newDate = new Date(today.setDate(today.getDate() - i));
    //     var newDate = new Date(new Date().setDate(today.getDate() - i));
    //     daysSorted.push(weekday[newDate.getDay()]);
    // }

    for (var i = 0; i < goBackDays; i++) {
        var newDate = new Date(today.setDate(today.getDate() - 1));
        daysSorted.push(weekday[newDate.getDay()]);
    }
    return daysSorted.reverse();

}