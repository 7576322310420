import React from 'react';
import Approved from "../../../Assets/Images/Approved.svg";
import Reject from "../../../Assets/Images/Reject.svg";
import CloseIcon2 from "../../../Assets/Images/CloseIcon2.svg";
import Modal from '../index';
import "./index.css";
const ConfirmationModal = ({ show, status, onPopupClose, loading }) => {
    return (
        <Modal show={show} onHide={onPopupClose} >
            <div style={{ backgroundColor: '#fff', minWidth: 534, height: 303, padding: 15 }}>
                <div
                    className="confirmation-closeIconContainer"
                >
                    <img onClick={onPopupClose} src={CloseIcon2} alt="Close Button" />
                </div>
                <div
                    className="confirmation-approveRejectIconContainer"
                >
                    {status ? <img src={Approved} alt="Approve" /> : <img src={Reject} alt="Reject" />}
                </div>
                <div className="confirmation-overlayUpdateStatus">
                    {loading ? <div>Processing...</div> : status ? "Approved" : "Rejected"}
                </div>
            </div>
        </Modal>
    )
}

export default ConfirmationModal