import { call, put, takeEvery } from "redux-saga/effects";
import { activeInActivePanelistStatusTypes } from "../types";
import { panelistActiveInActiveSuccess, panelistActiveInActiveRes, activeInActivePanelistError } from "../actions/panelist.action";
import { onActiveOrInActivePanelist } from "../services/api"
import { el } from "date-fns/locale";

function* fetchActiveOrInActivePanelist({ payload }) {
    try {
        const activeOrInActivePanelist = yield call(onActiveOrInActivePanelist, payload);

        if (activeOrInActivePanelist?.status === 200) {
            yield put(panelistActiveInActiveSuccess("success"))
        } else {
            yield put(panelistActiveInActiveSuccess(activeOrInActivePanelist.message));

        }

    } catch (e) {
        yield put(activeInActivePanelistError(e));
    }
}

export default function* saga() {
    yield takeEvery(activeInActivePanelistStatusTypes.FETCH, fetchActiveOrInActivePanelist);
}
