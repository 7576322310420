import axios from 'axios';
import {
    // postData,
    // patchData,
    // postDataLogin, postDataWithParam, postDataForBearerToken, postDataForAssignment,
    // postDataInParams,
    // postDataForCureBayContent,
    // postDataStatus,
    getData,
    getDataURL
} from '../CommonUtils/api';
// import { urls } from '../CommonUtils/config';
// const tokenUrl = urls.tokenUrl;
// const curebayBaseUrl = urls.curebayBaseUrl;
const baseUrl = "https://f0613e37-7d94-4dc5-9b8d-05787cd9e515.mock.pstmn.io";
export async function getDataPM(url, headers) {
    try {
        const configurationObject = {
            method: 'get',
            url: baseUrl + url,
            headers
        };
        const response = await axios(configurationObject)
            .then((response) => response.data)
            .catch((err) => Promise.reject(err.message))
            .then((res) => res);;
        return response;
    } catch (error) {
        console.log('Warning', error.response.data.message);
        return error.response;
    }
}


// panelistsAndLeadsAddition
export const getDashboardPanelistsAndLeads = async () => {
    return await getDataURL("../assets/mockapi/panelistsAndLeadsAddition.json");
}
export const getDashboardPanelists = async () => {
    return await getDataPM("/panelistAddition");
}
export const getDashboardLeads = async () => {
    return await getDataURL("https://api.helium.io/v1/hotspots")
}

// export const getDashboardAssignments = async () => {
//     return await getDatas("https://api.helium.io/v1/cities", { headers: { 'Content-Type': 'application/json' } })
// }

export const getDashboardPayments = async () => {
    return await getDataURL("../assets/mockapi/payments.json");
}
export const getDashboardTotalData = async (datePram) => {
    return await getData("getDashBoardDetails" + datePram).then((response) => response.data)
        .catch((err) => Promise.reject(err.message))
        .then((res) => res);
}
// export const getAllAssignments = async () => {
//     return await getData("showALLAssignments?panelistId=-1")
//         .then((response) => response.data)
//         .catch((err) => Promise.reject(err.message))
//         .then((res) => res);
// }

export const getPromotionList = async () => {
    return await getDataURL("../assets/mockapi/promotionList.json");
}
