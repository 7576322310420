import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { surgeryReferLeadsFetch, surgeryReferLeadsUpdateStatusReset } from "../../actions/surgeryReferLeads.action";
import ConfirmationModal from "../../Components/Modal/ConfirmationModal";
import SurgeryLeadCard from "./SurgeryLeadCard";

const SurgeryLeadsContainer = ({ loading, surgeryLeads }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(surgeryReferLeadsFetch());
    }, [dispatch]);
    const updateStatus = useSelector((store) => store.surgeryReferLeads.UpdateStatus);
    const handleModalOpen = () => {
        dispatch(surgeryReferLeadsUpdateStatusReset());
        dispatch(surgeryReferLeadsFetch());
    }
    return <div
        className='surgeryLeads-container'
    >
        {loading ? <h1 style={{ margin: "auto" }}>Loading...</h1> :
            surgeryLeads?.length > 0 ? surgeryLeads.map((data) => (
                <SurgeryLeadCard key={data.id} data={data} />
            ))
                : <h1 style={{
                    margin: 'auto'
                }}>No Data found</h1>
        }
        <ConfirmationModal show={updateStatus.modalOpen} loading={updateStatus.loading} status={updateStatus.data?.status === "Approved"} onPopupClose={handleModalOpen} />
    </div>
}
export default SurgeryLeadsContainer;