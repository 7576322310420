import { call, put, takeEvery } from "redux-saga/effects";
import { recentPanelistTypes } from "../types";
import { panelistSuccess, panelistError } from "../actions/panelist.action";
import { getRecentPanelist } from "../services/api"

function* fetchPanelist() {
    try {
        const panelist = yield call(getRecentPanelist);
        yield put(panelistSuccess(panelist.data));
    } catch (e) {
        yield put(panelistError(e));
    }
}

export default function* saga() {
    yield takeEvery(recentPanelistTypes.FETCH, fetchPanelist);
}
