import React from 'react'
import { Link } from 'react-router-dom'
import "./index.css"
const Health = () => {
    return (
        <div className='health-container'>
            <h1>Health check Successfully</h1>
            <Link className='health-link' to="/admin">Home</Link>
        </div>
    )
}

export default Health