import axios from 'axios';
import { urls } from './config';
import { encryptData } from '../Components/encryptDecrypt';
const baseUrl = urls.engageBaseUrl;
const baseUrlCureBay = urls.curebayBaseUrl;
const storedToken = localStorage.getItem("authToken");


export async function getData(url) {
  try {
    const configurationObject = {
      method: 'get',
      url: `${baseUrl}${url}`,
      'Authorization': `Bearer ${storedToken}`

    };
    // console.log('configurationObject', configurationObject);
    const response = await axios(configurationObject);
    // console.log('getData response', response);
    return response;
  } catch (error) {
    console.log('Warning', error.response.data.message);
    return error.response;
  }
}


export async function getDataFromCureBay(url) {
  try {
    const configurationObject = {
      method: 'get',
      url: `${baseUrlCureBay}${url}`,
      headers: {

        'Authorization': `Basic ${storedToken}`
      }
    };
    // console.log('configurationObject', configurationObject);
    const response = await axios(configurationObject);
    // console.log('getData response', response);
    return response;
  } catch (error) {
    console.log('Warning', error.response.data.message);
    return error.response;
  }
}

export async function postData(url, assignmentId) {
  const storedToken = localStorage.getItem("authToken");


  try {
    const configurationObject = {
      method: 'post',
      url: `${baseUrl}${url}`,
      data: {
        assignmentId,
        status: ["approved", "rejected", "submitted"]
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${storedToken}`

      },
    };
    console.log('configurationObject', configurationObject);
    const response = await axios(configurationObject);
    console.log('response', response);
    return response;
  } catch (error) {
    console.log('Warning', error.response.data.message);
    return error.response;
  }
}



export async function postDataStatus(url, data) {
  const storedToken = localStorage.getItem("authToken");


  try {
    const configurationObject = {
      method: 'POST',
      url: `${baseUrl}${url}`,
      data,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${storedToken}`
      },
    }
    return await axios(configurationObject).then((response) => response.data)
      .catch((err) => Promise.reject(err.message))
      .then((res) => res);

  } catch (error) {
    console.log('Warning', error.response.data.message);
    return error.response;
  }
}

export async function postDataForAssignment(url, data) {
  const storedToken = localStorage.getItem("authToken");


  try {
    const configurationObject = {
      method: 'post',
      url: `${baseUrl}${url}`,
      data,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    console.log('configurationObject', configurationObject);
    const response = await axios(configurationObject);
    console.log('response', response);
    return response;
  } catch (error) {
    console.log('Warning', error.response.data.message);
    return error.response;
  }
}

export async function postDataWithParam(url, reqData) {
  console.log('url', url);
  console.log('reqData', reqData);
  try {
    const response = await axios.post(`${baseUrl}${url}`, null, {
      params: reqData,
    });
    console.log('response', response);
    return response;
  } catch (error) {
    console.log('Warning', error.response.data.message);
    return error.response;
  }
}

export async function patchData(url, reqData) {
  const storedToken = localStorage.getItem("authToken");


  try {
    const configurationObject = {
      method: 'patch',
      url: `${baseUrl}${url}`,
      data: reqData,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${storedToken}`
      },
    };
    console.log('configurationObject', configurationObject);
    const response = await axios(configurationObject);
    console.log('response', response);
    return response;
  } catch (error) {
    console.log('Warning', error.response.data.message);
    return error.response;
  }
}

export async function postDataForBearerToken(url) {

  // let reqData = new FormData();
  // reqData.append('grant_type', 'password');
  // reqData.append('username', payload.username);
  // reqData.append('password', payload.password);
  // reqData.append('grant_type', payload.grant_type);
  // console.log(reqData, "RequestData");
  let payload = {
    "userCode": urls.user_code,
    "passWord": urls.password
  }
  const encryptedData = encryptData(payload)
  const storedToken = localStorage.getItem("authToken");


  let token = btoa(payload.authUserName + ":" + payload.authPassword);
  // localStorage.setItem('authToken', token);
  try {

    const response = await axios.post(url, encryptedData, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })



    console.log('response', response);
    return response;
  } catch (error) {
    console.log('Warning', error.response.data.message);
    return error.response;
  }
}

export async function postDataLogin(url, reqData) {
  try {
    console.log('reqData', reqData);
    localStorage.setItem('authToken', reqData.payload.token);
    const payload_login = {
      channel: reqData.payload.channel,
      passWord: btoa(reqData.payload.password),
      userCode: reqData.payload.emailId
    }
    const encryptedData = encryptData(payload_login);
    const response = await axios.post(url, encryptedData, {
      headers: {
        'Authorization': `Bearer ${reqData.payload.token}`
      }
    })

    console.log('response', response);
    return response;
  } catch (error) {
    console.log('Warning', error.response.data.message);
    return error.response;
  }
}
export const postDataInParams = async (url) => {
  try {
    const response = await axios.post(`${baseUrl}${url}`, null);
    return response;
  } catch (error) {
    console.log('Warning', error.response.data.message);
    return error.response;
  }
}
export async function postDataForCureBayContent(url, data) {
  const storedToken = localStorage.getItem("authToken");


  try {
    const configurationObject = {
      method: 'post',
      url: `${baseUrl}${url}`,
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Basic ${storedToken}`
      },
    };
    const response = await axios(configurationObject);
    return response;
  } catch (error) {
    console.log('Warning', error.response.data.message);
    return error.response;
  }
}

export async function getDataURL(url, headers) {
  try {
    const configurationObject = {
      method: 'get',
      url,
      headers
    };
    const response = await axios(configurationObject)
      .then((response) => response.data)
      .catch((err) => Promise.reject(err.message))
      .then((res) => res);
    return response;
  } catch (error) {
    console.log('Warning', error.response.data.message);
    return error.response;
  }
}