import { call, put, takeEvery } from "redux-saga/effects";
import { promotionListError, promotionListSuccess } from "../actions/promotionList.action";
import { getPromotionList } from "../services/dashboard.api";
import { promotionListTypes } from "../types";

function* fetchPromotionList({ payload }) {
    try {
        const promotionList = yield call(getPromotionList, payload);
        yield put(promotionListSuccess(promotionList));
    } catch (e) {
        yield put(promotionListError(e));
    }
}

export default function* saga() {
    yield takeEvery(promotionListTypes.FETCH, fetchPromotionList);
}
