import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "@redux-saga/core";
import rootReducer from "./reducers";
import sagas from "./sagas"
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(sagaMiddleware)
        // ,
        // (window.devToolsExtension && window.devToolsExtension()) ||
        // (window.__REDUX_DEVTOOLS_EXTENSION__ &&
        //     window.__REDUX_DEVTOOLS_EXTENSION__())
    )
)
sagaMiddleware.run(sagas);
export default store;