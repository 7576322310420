import { addAssignmentTypes, assignmentsTypes } from "../types";
const { FETCH, SUCCESS, ERROR, RESET } = assignmentsTypes;

export function assignmentsFetch() {
    return {
        type: FETCH
    }
}
export function assignmentsSuccess(val) {
    return {
        type: SUCCESS,
        payload: val
    }
}
export function assignmentsError(val) {
    return {
        type: ERROR,
        payload: val
    }
}
export function assignmentsReset() {
    return {
        type: RESET
    }
}

// addAssignmement
export function addAssignmentsFetch(val) {
    return {
        type: addAssignmentTypes.FETCH,
        payload: val
    }
}
export function addAssignmentsSuccess(val) {
    return {
        type: addAssignmentTypes.SUCCESS,
        payload: val
    }
}
export function addAssignmentsError(val) {
    return {
        type: addAssignmentTypes.ERROR,
        payload: val
    }
}
export function addAssignmentsReset() {
    return {
        type: addAssignmentTypes.RESET
    }
}
export function addAssignmentUpdate(val) {
    return {
        type: addAssignmentTypes.UPDATE,
        payload: val
    }
}

export function addAssignmentRes(val) {
    return {
        type: addAssignmentTypes.RESPONSE,
        payload: val
    }
}
