import React, {
    useEffect, useState
} from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import CalendarIcon from "../../Assets/Images/CalendarIcon.svg";
import { formatDate } from "../../CommonUtils/commonUtils";
import "./index.css";
// const isDate = (date) => {
//   return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
// }
// const todayDate = new Date();
const StartCalendar = ({ currentDate, title, onDateChange, disableDate }) => {
    const initialDate = isNaN(new Date(currentDate)) ? new Date() : new Date(currentDate);
    // console.log(new Date(currentDate), "currentDate");
    // debugger
    const [date, setDate] = useState(initialDate);
    const [dateToDisplay, setDateToDisplay] = useState(formatDate(initialDate));
    const [calenderVisible, setcalenderVisible] = useState(false);
    const onDaySellected = () => {
        const formattedDate = formatDate(date)
        setDateToDisplay(formattedDate);
        onDateChange(formattedDate);
        setcalenderVisible(false);
    }

    // useEffect(() => {
    //   onDaySellected()
    // }, [date])

    // useEffect(() => {
    //   setDate(isNaN(new Date(storedDate)) ? new Date() : new Date(storedDate))
    // }, [storedDate])

    const onChange = (date) => {
        setDate(date);
    };
    // const updateDate = React.useCallback(() => {
    //   onDateChange(startDateForDisable);
    // }, [onDateChange, startDateForDisable])

    // useEffect(() => {
    //   endDateError?.(isDate(startDateForDisable) && Date.parse(startDateForDisable) >= Date.parse(date));
    //   if (isDate(startDateForDisable) && Date.parse(startDateForDisable) >= Date.parse(date)) {
    //     // setDate(new Date(startDateForDisable));
    //     endDateError();
    //     // setDateToDisplay(startDateForDisable);
    //     // updateDate()
    //     // onDateChange(startDateForDisable);
    //   }
    // }, [date, startDateForDisable, onDateChange])

    useEffect(() => {
        setDate(new Date(dateToDisplay))
    }, [dateToDisplay])

    return (
        <div>
            <p className="calendarTitle">{title}</p>
            <div className="calendarContainer" onClick={() => {
                setcalenderVisible(true);
            }}>
                {dateToDisplay ? <p className="calendarDate">{dateToDisplay}</p> : null}
                <div className="calendarIconContainer">
                    <img className="calendarIcon" src={CalendarIcon} alt="Calendar" />
                </div>
            </div>
            {calenderVisible ? <div className="calendarOverlay" onClick={() => {
                setcalenderVisible(false);
            }}>
                <div className="calendarOverlayContainer" >
                    <div className="calenderHolder" onClick={(e) => e.stopPropagation()}>
                        <p className="calenderTitle">{title}</p>
                        <Calendar
                            // minDate={isDate(startDateForDisable) ? new Date(startDateForDisable) : todayDate}
                            {...disableDate}
                            // minDate={todayDate}
                            className="react-calendar"
                            calendarType="US"
                            onChange={onChange}
                            value={date}
                        />
                        <div className="calendarButtonContainer">
                            <div className="calendarOverlayButton calendarCancelButton" onClick={() => {
                                setcalenderVisible(false)
                            }}>Cancel</div>
                            <div className="calendarOverlayButton calendarSaveButton" onClick={() => {
                                onDaySellected();
                            }}>Done</div>
                        </div>
                    </div>
                </div></div> : null
            }
        </div >
    );
}
// export default React.memo(CalendarComponent);
export default StartCalendar;
