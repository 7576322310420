import React, { useState, useEffect } from "react";
import "./index.css";

import AddIconWhite from "../../Assets/Images/AddIconWhite.svg";

import CalendarComponent from "../../Components/calendar/index";
import { useDispatch, useSelector } from "react-redux";
import { addAssignmentRes, addAssignmentsFetch } from "../../actions/assignments.action";
import { formatDate } from "../../CommonUtils/commonUtils";
import Modal from "../../Components/Modal";
import { Link, NavLink } from "react-router-dom";
import ApproveRejectBtn from "../../Components/ApproveRejectBtn";
const errorMsges = {
  name: "Name is required",
  description: "Description is required",
  // startDate: "Start Date is required",
  endDate: "End date should be greater than start date",
}
const formattedCurretDate = formatDate(new Date());
const initialState = {
  name: "",
  description: "",
  startDate: formattedCurretDate,
  endDate: formattedCurretDate,
  status: "active"
}
export default function AddNew() {
  const [assignmentDetails, setAssignmentDetails] = useState(initialState)
  const [errorMsg, setErrorMsg] = useState({});

  const addAssignmentStore = useSelector((store) => store.addAssignment)
  const dispatch = useDispatch();

  const handleSubmit = () => {


    const requiredFields = Object.keys(errorMsges);
    const currentErrorMsg = { ...errorMsg };
    requiredFields.forEach((field) => {
      if (assignmentDetails[field] === "") {
        currentErrorMsg[field] = errorMsges[field];
      } else {
        delete currentErrorMsg[field];
      }
    })
    if (Date.parse(assignmentDetails["startDate"]) > Date.parse(assignmentDetails["endDate"])) {
      currentErrorMsg["endDate"] = errorMsges["endDate"];
    } else {
      delete currentErrorMsg["endDate"];
    }

    if (Object.keys(currentErrorMsg).length === 0) {
      const { description, startDate, endDate, name, status } = assignmentDetails;
      dispatch(addAssignmentsFetch({ description, startDate, endDate, name, status }));
      setAssignmentDetails(initialState)

    } else {
      setErrorMsg(currentErrorMsg);
    }

  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (assignmentDetails[name] !== value) {
      setAssignmentDetails({ ...assignmentDetails, [name]: value })
    }
    // dispatch(addAssignmentUpdate({ [e.target.name]: e.target.value }))
  }

  // console.log(errorMsg, "error details");
  // console.log(assignmentDetails, "assignement details");
  const [isModalOpen, setModalOpen] = useState(false);
  const handleCloseModal = () => {
    setModalOpen(false)
    dispatch(addAssignmentRes("done"))
  }
  const { addAssignmentStatus } = addAssignmentStore;
  useEffect(() => {
    if (addAssignmentStatus === "success") {
      setModalOpen(true)
    }
  }, [addAssignmentStatus])
  const [endDateTouched, setEndDateTouched] = useState(false);
  const handleDateChangeError = (startDate, endDate) => {
    if (Date.parse(startDate) > Date.parse(endDate)) {
      const endDateErrorMsg = errorMsges["endDate"];
      if (endDateErrorMsg !== errorMsg["endDate"]) {
        setErrorMsg((errors) => ({ ...errors, endDate: endDateErrorMsg }));
      }
    } else {
      const newErrors = { ...errorMsg }
      delete newErrors["endDate"];
      setErrorMsg(newErrors);
    }
  }
  return (
    <>
      <div>
        <div className="addNew-titleContainer">
          <NavLink to={"/admin/assignments"} className="assignmentDetails-titleContainer">Assignments</NavLink>
          {" > "}
          Add New</div>
        {errorMsg["name"]?.length > 0 && <div className="addNew-errorMsg">{errorMsg.name}</div>}
        <input className="addNew-nameField" name="name" type={"text"} placeholder="Name"
          onChange={handleChange} value={assignmentDetails.name}
        />


        {errorMsg["description"]?.length > 0 && <div className="addNew-errorMsg">{errorMsg["description"]}</div>}
        <textarea
          className="addNew-textArea"
          placeholder="Type Panelist Assignment.."
          name="description"
          value={assignmentDetails.description}
          onChange={handleChange}
          rows="4"
        />

        <div className='addNew-statusBlock'>
          <div className='addNew-statusContainer'>
            <div>
              <input type={"checkbox"} id="activeContentStatus" name="status"
                value={assignmentDetails.status === "active" ? "inactive" : "active"}
                checked={assignmentDetails.status === "active"} onChange={handleChange}
              />
              <label htmlFor="activeContentStatus">Active</label>
            </div>
          </div>
        </div>

        {errorMsg["endDate"] && <div className="addNew-errorMsg">{errorMsg["endDate"]}</div>}
        <div className="addNew-filterContainer">
          <div className="addNew-calendalContainer">
            <div className="addNew-startDateContainer">
              <CalendarComponent title="Choose Start Date"
                onDateChange={(startDate) => {
                  if (assignmentDetails["startDate"] !== startDate) {
                    setAssignmentDetails((details) => ({ ...details, startDate }))
                    if (endDateTouched) {
                      handleDateChangeError(startDate, assignmentDetails["endDate"])
                    }
                  }
                }}
                disableDate={{ minDate: new Date() }}
              />
              {errorMsg["startDate"]?.length > 0 && <div>{errorMsg["startDate"]}</div>}
            </div>
            <div className="addNew-endDateContainer">
              <CalendarComponent title="Choose End Date"
                onDateChange={(endDate) => {
                  if (assignmentDetails["endDate"] !== endDate) {
                    setAssignmentDetails((details) => ({ ...details, endDate }))
                    handleDateChangeError(assignmentDetails["startDate"], endDate)
                  }

                  if (!endDateTouched) {
                    setEndDateTouched(true)
                  }
                }}
                disableDate={{ minDate: new Date() }}
              />
            </div>
          </div>
          <div className="addNew-addNewButtonContainer">
            <div className="addNew-addNewButtonWrapper">
              {addAssignmentStore.loading ? "loading..." : <div className="addButton-container" onClick={handleSubmit}>
                <img src={AddIconWhite} alt="Add Assignment" />
                <div className="addButton-textContainer">
                  Add New
                </div>
              </div>}
              {/* <AddButton /> */}
            </div>
          </div>
        </div>
      </div>

      <Modal show={isModalOpen} onHide={handleCloseModal}>
        <div className="addNew-modal-body">
          <h1>Assignment submit Successfully</h1>
          <Link className="addNew-modal-link" to="/admin/assignments" onClick={handleCloseModal}>
            <ApproveRejectBtn variant={"approve"}>OKAY</ApproveRejectBtn>
          </Link>
        </div>
      </Modal>

    </>
  );
  //   }
}
