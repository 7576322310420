import React, { useEffect, useState } from 'react'
import "./index.css"
import mailIcon from "../../Assets/Images/mailicon.svg";

import IconDropDown from './IconDropDown';
import ImagePlaceholder from '../../Components/ImagePlaceholder';
import { getDataFromStore } from '../../CommonUtils/commonUtils';
import { countryList, getCountryCode } from '../../utils';

const Profile = () => {
    const [profileDetails, setProfileDetails] = useState({
        name: "Ram",
        surname: "Sharma",
        emailAddress: "ramsharma@gmail.com",
        phoneNumber: "8866472553",
        role: "Admin",
        country: { name: "India", code: "IN" }
    })

    useEffect(() => {
        try {
            const parsedUser = JSON.parse(getDataFromStore("userData"));
            const { user: { firstName, lastName, email, mobile, roleName, country } } = parsedUser;
            setProfileDetails({ name: firstName, surname: lastName, emailAddress: email, phoneNumber: mobile, role: roleName, country: { name: country, code: getCountryCode(country).code } });
        } catch (e) {
            setProfileDetails(null);
        }
    }, [])
    const handleInputChange = (e) => {
        setProfileDetails({ ...profileDetails, [e.target.name]: e.target.value })
    }
    // const handleCountryChange = (e) => {
    //     const { name, value, selectedIndex } = e.target
    //     setProfileDetails({ ...profileDetails, country: { name: e.target[selectedIndex].innerText, code: e.target.value } })
    // }


    if (profileDetails === null)
        return <h1>Data not found</h1>

    return (
        <>
            <div className="profilepage-breadcrumb">Profile</div>
            <div className='profilepage-title'>Personal Info</div>
            <div className='profileChane-container'>
                {/* <img src={profileImg} width={86} height={86} style={{ borderRadius: "50%" }} /> */}
                <ImagePlaceholder name={profileDetails.name} />
                <div style={{ display: "flex", alignItems: "center" }}>
                    <h2 className='profileChange-title'>Profile</h2>
                    {/* <p className='profileChane-subtitle'>Update your photo and personal details.</p> */}
                </div >
                {/* <button className='profileChangeBtn profileChange-cancelBtn'>Cancel</button>
                <button className='profileChangeBtn profileChange-saveBtn'>Save</button> */}
            </div>
            <div className='profilePage-inputGroup profilePage-inputBorder'>
                <label htmlFor='name' className='profilePage-inputLabel'>Name:</label>
                <div style={{ flex: 2 }}>
                    <div style={{ width: 466, display: "flex", justifyContent: "space-between" }}>
                        <input id='name' className='profilePage-inputField'
                            style={{ width: 149 }}
                            type={"text"} name="name"
                            placeholder='First Name'
                            value={profileDetails.name} onChange={handleInputChange} />
                        <input className='profilePage-inputField' type={"text"}
                            style={{ width: 149 }}
                            placeholder="Last Name"
                            name='surname'
                            value={profileDetails.surname} onChange={handleInputChange} />
                    </div>
                </div>
            </div>
            <div className='profilePage-inputGroup profilePage-inputBorder ' >
                <label htmlFor='email' className='profilePage-inputLabel'>Email Adress:</label>
                <div style={{ flex: 2, position: "relative" }}>
                    <img src={mailIcon} className="profilePage-inputIcon" style={{ top: "50%", left: 18, transform: "translateY(-50%)" }} alt="mail icon" />
                    <input id='email' className='profilePage-inputField profilePage-mailInput' style={{ paddingLeft: 60 }} type={"text"} name="emailAddress"
                        placeholder='Your Email Address'
                        value={profileDetails.emailAddress} onChange={handleInputChange} />
                </div>
            </div>
            <div className='profilePage-inputGroup profilePage-inputBorder'>
                <label htmlFor='phone' className='profilePage-inputLabel'>Phone Number:</label>
                <div style={{ flex: 2 }}>
                    <input id='phone' className='profilePage-inputField' type={"text"}
                        placeholder="Your Phone Number"
                        name='phoneNumber'
                        value={profileDetails.phoneNumber}
                        onChange={handleInputChange} />
                </div>
            </div>
            <div className='profilePage-inputGroup profilePage-inputBorder'>
                <label htmlFor='role' className='profilePage-inputLabel'>Role:</label>
                <div style={{ flex: 2 }}>
                    <input id='role' className='profilePage-inputField' type={"text"}
                        placeholder="Your Role"
                        name="role" value={profileDetails.role} onChange={handleInputChange} />
                </div>
            </div>



            <div className='profilePage-inputGroup '>
                <label htmlFor='country' className='profilePage-inputLabel'>Country:</label>
                <div style={{ flex: 2, position: "relative" }}>
                    {/* <img src={indianFlag} style={{ top: 12, left: 15 }} className="profilePage-inputIcon" /> */}
                    <IconDropDown list={countryList}
                        onChange={(country) => setProfileDetails({ ...profileDetails, country })}
                        activeOption={profileDetails.country} />
                    {/* <input id='country' list='countryList' name='country' className='profilePage-inputField' style={{ paddingLeft: 60 }} type={"text"}
                            value={profileDetails.country}
                            placeholder="Select Your Country"
                            onChange={handleInputChange} /> */}
                    {/* <img src={downArrow} className="profilePage-inputIcon" style={{ right: 27, top: 23 }} /> */}
                </div>
            </div>
        </>
    )
}

export default Profile