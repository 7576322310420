import {
    loginSuccess,
    loginError,
    bearerTokenSuccess,
    bearerTokenError,
} from "../actions/login.action";
import { call, put, takeEvery } from "redux-saga/effects";
import { userLoginTypes, userBearerTokenTypes } from "../types";
import { getBearerToken, loginUser } from "../services/api"
import { setDataInStore, storeToken } from "../CommonUtils/commonUtils";

function* userLogin(payload) {
    try {
        const data = yield call(loginUser, payload);
        console.log('userLogin', data);
        if (data.message === 'Validation Failed.' || (data?.user?.roleName !== "Engage Admin" && data?.user?.roleCode !== "ENGAGEADMIN")) {
            yield put(loginError(data));
        } else {
            setDataInStore("userData", JSON.stringify(data));
            yield put(loginSuccess(data));
        }
    } catch (e) {
        yield put(loginError(e));
    }
}

function* getToken({ payload }) {
    try {
        const tokenData = yield call(getBearerToken, payload);
        yield call(storeToken, tokenData);
        yield put((bearerTokenSuccess()));
    } catch (e) {
        yield put(bearerTokenError(e));
    }
}

export default function* saga() {
    yield takeEvery(userLoginTypes.FETCH, userLogin);
    yield takeEvery(userBearerTokenTypes.FETCH, getToken);
}
