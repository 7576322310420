import { dashboardPaymentsTypes } from "../../types";
const initialState = {
    data: [],
    loading: false,
    error: ""
}
const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case dashboardPaymentsTypes.FETCH:
            return { ...state, loading: true, error: "" }
        case dashboardPaymentsTypes.SUCCESS:
            return { ...state, data: payload, loading: false }
        case dashboardPaymentsTypes.ERROR:
            return { ...state, loading: false, error: payload }
        case dashboardPaymentsTypes.RESET:
            return initialState;
        default:
            return state;
    }
}

export default reducer;