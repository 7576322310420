import { dataCollectionListTypes } from "../types";

export function dataCollectionListFetch(val) {
    return {
        type: dataCollectionListTypes.FETCH,
        payload: val,
    }
}
export function dataCollectionListSuccess(val) {
    return {
        type: dataCollectionListTypes.SUCCESS,
        payload: val
    }
}
export function dataCollectionListError(val) {
    return {
        type: dataCollectionListTypes.ERROR,
        payload: val
    }
}
export function dataCollectionListReset() {
    return {
        type: dataCollectionListTypes.RESET
    }
}

