import React, { useEffect, useRef, useState } from 'react';
// import SearchIcon from '../../Assets/Images/Search.svg';
import playIcon from '../../Assets/Images/playIcon.svg';
import "./index.css";
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import AddIconWhite from "../../Assets/Images/AddIconWhite.svg";
// import ArrowRight from "../../Assets/Images/ArrowRight.svg";
import { cureBayContentListFetch } from '../../actions/cureBayContent.action';
import Pagination from '../../Components/Pagination';
import ImagePlaceholder from '../../Components/ImagePlaceholder';


// "id": 1,
// "name": "Ajeet-4 Yrs Experience",
// "mob": "8349770264",
// "email": "ajeet@email.com",
// "dateHired": "02/06/2018",
// "previousDesignation": "Facilator",
// "promotedDate": "02/03/2022",
// "pomotedTo": "Coordinator"

// const promotionCardsData = [
//     {
//         "id": 7,
//         "name": "Assignment 7",
//         "description": "Gather people of village and explain about thefamous doctor visiting the eClinic",
//         "startDate": "2022-05-12T00:00:00.000+00:00", "endDate": "2022-07-12T00:00:00.000+00:00",
//         "status": "Active"
//     },
// ]
const isVideo = (url) => {
    return /.+\.(mp4)$/.test(url);
}

const PromotionCard = ({ option }) => {
    return (
        <div className="curebayContentList-listContainer">
            <div className="cureBayContent-promoMedia" >
                {isVideo(option.url) ?
                    <VideoThumbnail url={option.url} />
                    :
                    <>
                        <ImagePlaceholder path={option.url} style={{ width: "100%", height: "100%", background: "transparent" }} imageStyle={{ objectFit: "contain" }} />
                        {/* <img className="cureBayContent-promoImage" src={option.url} height="100%" alt="CureBay Content" /> */}
                    </>
                }
            </div>
            <div className="cureBayContentList-detailBlock">

                <div className="cureBayContent-descriptionDetails">
                    {option.description ? option.description : "N/A"}
                </div>
                <div className="cureBayContent-activeDate">
                    {option.startDate
                        ? option.startDate.substring(0, 10)
                        : "N/A"} to {" "}
                    {option.endDate
                        ? option.endDate.substring(0, 10)
                        : "N/A"}

                </div>
            </div>

            {/* <NavLink
            to={"/admin/assignments/" + option.id}
            className="assignment-responseBlock"
        >
            <div className="assignment-responseInnerBlock">
                <div className="assignment-responseInnerBlock2">
                    <p className="assignment-responseText">
                        View Responses
                    </p>
                    <img
                        className="assignment-rigthArrow"
                        src={ArrowRight}
                        alt={"View Responses"}
                    />
                </div>
            </div>
        </NavLink> */}
        </div>)
}
const VideoThumbnail = ({ url }) => {
    const [video, playVideo] = useState(false);
    const videoInput = useRef(null);

    const handleVideo = () => {
        playVideo(!video);
        videoInput.current.play();
    }
    return (
        <div className={`video-wrapper ${!video && "video-wrapper-overlay"}`}>
            {!video && <img className='playIcon' src={playIcon} onClick={handleVideo} alt="video play icon" />}
            <video
                className='cureBayContent-promoVideo'
                height={"auto"}
                controls={video}
                ref={videoInput}
                onPause={(e) => {
                    if (e.target.readyState !== 1) {
                        playVideo(false);
                    }
                }}
            >
                <source src={url} type="video/mp4" />

                Your browser does not support the video tag.
            </video>
        </div>)
}
const CureBayContentList = () => {
    // const [searchTerm, setSearchTerm] = useState("");
    // const handleSearch = (e) => {
    //     setSearchTerm(e.target.value);
    // }
    const dispatch = useDispatch();
    const { data, loading, } = useSelector((store) => store.cureBayContentList)
    // console.log(data, "curebay content list data")
    useEffect(() => {
        dispatch(cureBayContentListFetch())
    }, [dispatch])
    // console.log(data, "from store")
    return (
        <div>
            <div className="promoList-breadcrumb">CureBay Content</div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    <div className='promoList-title'>CureBay promotion content list</div>
                    <p>Total there are {loading ? "counting..." : data?.length ? data.length : "N/A"} Content</p>
                </div>
                <NavLink className={"curebayContent-addButton"} to={"/admin/cureBayContent/addNew"}>
                    <img src={AddIconWhite} style={{ paddingRight: 8 }} alt={"Add New CureBay Content"} />
                    Add New
                </NavLink>

            </div>
            {/* <div class="rewards-filterContainer"> */}
            {/* <div className="promo-searchContainer">
                <img src={SearchIcon} className="promo-searchIcon" alt="logo" />
                <input className="promo-searchInput" placeholder="Search by Name" value={searchTerm} onChange={handleSearch} />
            </div> */}
            {/* </div> */}
            {
                loading ?
                    <h1 style={{ textAlign: 'center' }} > Loading...</h1> :
                    data?.length > 0 ?
                        <div className="promo-profile-container" >
                            {data?.map((card) => (
                                <PromotionCard key={card.id}
                                    // {...data}
                                    option={card}
                                />
                            ))}
                            <Pagination />
                        </div>
                        : <h1 style={{ textAlign: "center" }}>No Content found</h1>
            }


        </div>
    )
}

export default CureBayContentList