import React from 'react'
import "./index.css"
const Modal = ({ show, onHide, children }) => {
    return (
        <>
            {show && <div
                className="modal-overlay"
                onClick={onHide}
            >
                <div
                    className="modal-main"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {children}
                </div>
            </div>}
        </>
    )
}

export default Modal