import { call, put, takeEvery, select } from "redux-saga/effects";
import { assignmentDetailsTypes } from "../types";
import { assignmentDetailsSuccess, assignmentDetailsError } from "../actions/assignmentDetails.action";
import { postAssignmentStatus } from "../services/api"
function* updateAssignmentStatus({ payload }) {
    const { data } = yield select((state) => state.assignmentDetails)
    try {
        const updateStatus = yield call(postAssignmentStatus, payload);
        if (updateStatus.status === 200) {
            const objIndex = data.panelists.findIndex((obj) => obj.panelistAssignmentId === payload.panelistAssignmentId)
            data.panelists[objIndex].panelistAssignmentStatus = payload.status;
            yield put(assignmentDetailsSuccess(data));
        }
    } catch (e) {
        yield put(assignmentDetailsError(e));
    }
}

export default function* saga() {
    yield takeEvery(assignmentDetailsTypes.UPDATE, updateAssignmentStatus);
}
