import {
    getData, patchData, postData, postDataLogin, postDataWithParam, postDataForBearerToken, postDataForAssignment,
    postDataInParams,
    postDataForCureBayContent,
    postDataStatus,
    getDataFromCureBay
} from '../CommonUtils/api';
import { urls } from '../CommonUtils/config';
const tokenUrl = urls.tokenUrl;
const curebayBaseUrl = urls.curebayBaseUrl;


export const getAllAssignments = async () => {
    return await getData("showALLAssignments?panelistId=-1")
        .then((response) => response.data)
        .catch((err) => Promise.reject(err.message))
        .then((res) => res);
}

export const getAssignmentDetails = async (assignmentid) => {
    return await postData(`showMediaAndPanelistByAssignmentIdAndStatus`, assignmentid)
        .then((response) => response.data)
        .catch((err) => Promise.reject(err.message))
        .then((res) => res);
}
export const getAssignmentDetailsResponse = async (data) => {
    return await postDataStatus(`showMediaAndPanelistByAssignmentIdAndStatus`, data)
}

export const getRecentPanelist = async () => {
    return await getData("getAllReferredPanelistOrByStatus?status=")
        .then((response) => response.data)
        .catch((err) => Promise.reject(err.message))
        .then((res) => res);
}

export const getActivePanelist = async () => {
    return await getData("getAllDetailsOfPanelist")
        .then((response) => response.data)
        .catch((err) => Promise.reject(err.message))
        .then((res) => res);
}


export const onActiveOrInActivePanelist = async (data) => {
    //return await postDataStatus(`getpanelistByStatusAndId`, data)
    return await postDataInParams(`getpanelistByStatusAndId?id=${data?.id}&status=${data?.status}`)
        .then((response) => response.data)
        .catch((err) => Promise.reject(err.message))
        .then((res) => res);
}

export const getPanelistDetails = async (id) => {
    return await getData("getByPanelistId?id=" + id)
        .then((response) => response.data)
        .catch((err) => Promise.reject(err.message))
        .then((res) => res);
}

export const getReferredPanelist = async (id) => {
    return await getData("getReferredPanelistbyPanelistId?panelistId=" + id)
        .then((response) => response.data)
        .catch((err) => Promise.reject(err.message))
        .then((res) => res);
}

export const getLeadsByPanelistID = async (panelistID, pageNo, pageSize) => {
    return await postDataInParams(`showLeadsByPanelist?panelistId=${panelistID}&pageNo=${pageNo}&pageSize=${pageSize}`)
        .then((response) => response.data)
        .catch((err) => Promise.reject(err.message))
        .then((res) => res);
}

export const getActiveRewards = async () => {
    return await getData("getRewardConfigurationByActiveOrInactiveStatus?active=1")
        .then((response) => response.data)
        .catch((err) => Promise.reject(err.message))
        .then((res) => res);
}

export const getInactiveRewards = async () => {
    return await getData("getRewardConfigurationByActiveOrInactiveStatus?active=0")
        .then((response) => response.data)
        .catch((err) => Promise.reject(err.message))
        .then((res) => res);
}


export const postAssignmentStatus = async ({ panelisId, panelistAssignmentId, status }) => {
    return await patchData(`giveRewardToApprovedStatus?panelistId=${panelisId}&panelistAssignmentId=${panelistAssignmentId}&status=${status}`)
        .then((response) => response.data)
        .catch((err) => Promise.reject(err.message))
        .then((res) => res);
}

export const updatePanelistStatus = async ({ id, status }) => {
    return await postDataWithParam(`UpdateRefferedPanelistStatusById`, { id, status })
        .then((response) => response.data)
        .catch((err) => Promise.reject(err.message))
        .then((res) => res);
}

export const getBearerToken = async (payload) => {
    return await postDataForBearerToken(tokenUrl, payload)
        .then((response) => response.data)
        .catch((err) => Promise.reject(err.message))
        .then((res) => res);
}

export const loginUser = async (payload) => {
    return await postDataLogin(`${curebayBaseUrl}Login/`, payload)
        .then((response) => response.data)
        .catch((err) => Promise.reject(err.message))
        .then((res) => res);
}

export const addAssignment = async (payload) => {
    return await postDataForAssignment("addOrUpdateAssignment", payload).then((response) => response.data)
        .catch((err) => Promise.reject(err.message))
        .then((res) => res);
}
export const addCureBayContent = async (payload) => {
    return await postDataForCureBayContent("addAndUpdateCurebayContent", payload).then((response) => response.data)
        .catch((err) => Promise.reject(err.message))
        .then((res) => res);
}
export const getCureBayContentList = async () => {
    return await getData("getCurebayContent")
        .then((response) => response.data)
        .catch((err) => Promise.reject(err.message))
        .then((res) => res);
}


export const getDataCollectionList = async (payload) => {
    return await postDataStatus("showDataCollection", payload)
        .then((response) => response.data)
        .catch((err) => Promise.reject(err.message))
        .then((res) => res);
}


export const getDataCollectionDetails = async (payload) => {
    return await postDataStatus("datacollection/familydetails", payload)
        .then((response) => response)
        .catch((err) => Promise.reject(err.message))
        .then((res) => res);
}


export const getEClinicList = async () => {
    return await getDataFromCureBay("hospital/list/filter?type=C&status=1")
        .then((response) => response.data)
        .catch((err) => Promise.reject(err.message))
        .then((res) => res);
}

export const getSMList = async () => {
    return await getData("SMRequestPaymentApproval/list?VALIDATESTATUS=A")
        .then((response) => response.data)
        .catch((err) => Promise.reject(err.message))
        .then((res) => res);
}
