import React, {
    useState
} from 'react'
import "./index.css"
const ImagePlaceholder = ({ path, name, style, imageStyle }) => {
    const [imgLoaded, setImageLoaded] = useState(true)
    return (
        <div className="imagePlaceholder" style={{ ...style }}>
            {imgLoaded && path ?
                <img src={path} onLoad={() => setImageLoaded(true)} onError={() => setImageLoaded(false)} style={{ width: "100%", height: "100%", ...imageStyle }} alt={"Profile icon"} />
                :
                <span>{name ? name.charAt(0) : "N/A"}</span>
            }
            {/* <span>{name?.charAt(0)}</span> */}

        </div>
    )
}

export default ImagePlaceholder