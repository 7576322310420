import { cureBayContentTypes, cureBayContentListTypes } from "../types";

// GET CUREBAY CONTENT LIST
export function cureBayContentListFetch() {
    return {
        type: cureBayContentListTypes.FETCH
    }
}
export function cureBayContentListSuccess(val) {
    return {
        type: cureBayContentListTypes.SUCCESS,
        payload: val
    }
}
export function cureBayContentListError(val) {
    return {
        type: cureBayContentListTypes.ERROR,
        payload: val
    }
}
export function cureBayContentListReset() {
    return {
        type: cureBayContentListTypes.RESET
    }
}

// ADD CUREBAY CONTENT
export function cureBayContentFetch(val) {
    return {
        type: cureBayContentTypes.FETCH,
        payload: val
    }
}
export function cureBayContentSuccess(val) {
    return {
        type: cureBayContentTypes.SUCCESS,
        payload: val
    }
}
export function cureBayContentError(val) {
    return {
        type: cureBayContentTypes.ERROR,
        payload: val
    }
}
export function cureBayContentReset() {
    return {
        type: cureBayContentTypes.RESET
    }
}