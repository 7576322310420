import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import CureBayLogo from "../../Assets/Images/CureBayLogo.svg"
import userIcon from "../../Assets/Images/user.svg"
import logoutIcon from "../../Assets/Images/logout.svg"
import arr from "../../Assets/Images/arr.svg"

import {
  clearDataFromStore,
  getDataFromStore,
} from '../../CommonUtils/commonUtils';
import "./index.css";
export default function AppHeader() {
  const navigate = useNavigate()
  useEffect(() => {
    if (!getDataFromStore("loggedIn")) {
      navigate("/admin", { replace: true })
    }
  }, [navigate])


  return (
    <div className="appHeader-container">
      <img src={CureBayLogo} style={{ marginLeft: 30, height: "100%" }} alt="cureBay Logo" title='CureBay Logo' />
      <UserDropDown />
    </div>
  );
}
const UserDropDown = () => {

  const [isClicked, setClicked] = useState(false);
  const navigate = useNavigate();
  const logout = () => {
    clearDataFromStore("loggedIn");
    clearDataFromStore("token");
    clearDataFromStore("userData");
    clearDataFromStore("authToken");

    localStorage.clear();
    sessionStorage.clear()
    navigate("/admin", { replace: true })
  }
  const handleDropDown = () => {
    setClicked(!isClicked)
  }
  const userDropDownRef = useRef(null);
  useEffect(() => {
    const handleOutSideClick = (e) => {
      if (!userDropDownRef?.current?.contains(e.target)) {
        setClicked(false);
      }
    }
    if (isClicked) {
      window.addEventListener("mousedown", handleOutSideClick)
    } else {
      window.removeEventListener("mousedown", handleOutSideClick)
    }

  }, [isClicked])
  const [fullName, setFullName] = useState(null);
  const [timeStatus, setTimeStatus] = useState("");

  useEffect(() => {
    try {
      const { user: { firstName, lastName } } = JSON.parse(getDataFromStore("userData"));
      setFullName(firstName + " " + lastName);

      var today = new Date();
      var curHr = today.getHours();

      if (curHr < 12) {
        setTimeStatus("Good Morning !");
      } else if (curHr < 18) {
        setTimeStatus("Good Afternoon !");
      } else {
        setTimeStatus("Good Evening !");
      }
    } catch (e) {
      setFullName(null);
    }
  }, [])



  return <div ref={userDropDownRef} className='userDropDown-iconContainer'>
    <div className='userDropDown-icon' onClick={handleDropDown}><img src={userIcon} alt={"user icon"} /></div>
    {isClicked &&
      <div className='userDropDown-container'>
        {fullName && <div>Hi {fullName}</div>}
        <div style={{ color: "rgba(255, 255, 255, 0.6)" }}>{timeStatus}</div>
        <div className='userDropDown-blockContainer'>
          <NavLink to="/admin/profile"
            // onClick={handleDropDown}
            className='userDropDown-block'>Profile <img src={arr} style={{ marginLeft: "auto" }} alt={"Goto icon"} /></NavLink>
          <NavLink to="/admin/settings"
            // onClick={handleDropDown}
            className='userDropDown-block'>Settings <img src={arr} style={{ marginLeft: "auto" }} alt={"Goto icon"} /></NavLink>
        </div>
        <div className='userDropDown-logout' onClick={logout}>Logout <img style={{ marginLeft: "auto" }} src={logoutIcon} alt="logout icon" /></div>
      </div>
    }
  </div>
}
