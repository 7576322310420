import { combineReducers } from "redux";
import panelistAddedReducer from "./panelistAdded.reducer";
import leadsAddedReducer from "./leadsAdded.reducer";
import assignmentsAddedReducer from "./assignmentsAdded.reducer";
import paymentsTotalReducer from "./paymentsTotal.reducer";
import totalDataReducer from "./totalData.reducer";


export default combineReducers({
    panelistAdded: panelistAddedReducer,
    leadsAdded: leadsAddedReducer,
    assignmentsAdded: assignmentsAddedReducer,
    paymentsTotal: paymentsTotalReducer,
    totalData: totalDataReducer,
});