import React, { useState, useEffect } from "react";
import axios from "axios";
import DropDownComponent from "../../Components/DropDown/index";
import SearchIcon from "../../Assets/Images/Search.svg";
import Approved from "../../Assets/Images/Approved.svg";
import member from "../../Assets/Images/member.svg";
import Reject from "../../Assets/Images/Reject.svg";
import Tick from "../../Assets/Images/Tick.svg";
import ApproveRejectBtn from "../../Components/ApproveRejectBtn";
import Cross from "../../Assets/Images/Cross.svg";
import "../SwasthyaMitra/SMPage.css";
import ImagePlaceholder from "../../Components/ImagePlaceholder";
import Mail from "../../Assets/Images/Mail.svg";
import Phone from "../../Assets/Images/Phone.svg";
import loaction from "../../Assets/Images/loaction.svg";
import bank from "../../Assets/Images/bank.svg";
import IFSC from "../../Assets/Images/IFSC.svg";
import account from "../../Assets/Images/account.svg";
import ApproveModal from "../../Components/ApproveRejectButton/ApproveModal";
import RejectModal from "../../Components/ApproveRejectButton/RejectModal";
import TransactionModal from "../../Screens/SwasthyaMitra/transactiondetails";
import pdficon from "../../Assets/Images/pdficon.svg";
import nodatafound from "../../Assets/Images/nodatafound.svg";
import { urls } from "../../CommonUtils/config";
import "../../CommonUtils/api";

const SMAgreement = () => {
  const [list, setList] = useState([]);
  const storedToken = localStorage.getItem("authToken");

  const [searchQuery, setSearchQuery] = useState("");
  const [originalList, setOriginalList] = useState([]);
  const [loading, setLoading] = useState(true);

  const api = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${urls.engageBaseUrl}SMPayment/agreements`,
        { headers: { Authorization: `Bearer ${storedToken}` } }
      );
      const fetchedData = response?.data?.data;
      console.log("API Data:", fetchedData);
      setList(fetchedData);
      setOriginalList(fetchedData);
      setLoading(false);
    } catch (err) {
      console.error("Error", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    api();
  }, []);

  useEffect(() => {
    api();
  }, []);

  const handleSearchQuery = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    setLoading(true);
    console.log("Search Query:", query); // Debugging
    setLoading(false);

    if (query === "") {
      setList(originalList);
      console.log("Original List:", originalList); // Debugging
    } else {
      const filteredList = originalList.filter((item) =>
        item.smName.toLowerCase().includes(query.toLowerCase())
      );
      console.log("Filtered List:", filteredList); // Debugging
      setList(filteredList);
      setLoading(false);
    }
  };

  const handleDownloadClick = (filePath) => {
    const url = `${urls.gcpUrl}${filePath}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div>
      <div className="titleContainer">Swasta Mitra Agreements</div>
      <div className="filterContainer">
        <div className="searchContainer">
          <img src={SearchIcon} className="searchIcon" alt="logo" />
          <input
            className="searchInput"
            placeholder="Search by Name"
            value={searchQuery}
            onChange={handleSearchQuery}
          />
        </div>
      </div>
      {loading ? (
        <div className="loader"></div>
      ) : list && list.length > 0 ? (
        list?.map((data, i) => (
          <div key={i} className="swasthyamitracard">
            <div className="block">
              <div className="Details">
                <ImagePlaceholder name={data.smName.split(" ")[1].charAt(0)} />
                <div className="detailsContainer">
                  <div className="name">
                    <p className="cardTitle">{data?.smName}</p>
                    <div
                      onClick={() =>
                        handleDownloadClick(data.agreementAcceptedFile)
                      }
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        marginLeft: "50px",
                      }}
                    >
                      <img src={pdficon} className="Iconpdf" alt="logo" />
                      <span style={{ fontWeight: "bold", marginTop: "13px" }}>
                        Agreement
                      </span>
                    </div>
                  </div>

                  <div className="Detailspro">
                    <div className="Details">
                      <img src={member} className="Icon" alt="logo" />
                      <span className="botto-text">{data.smCode}</span>
                      <img src={Phone} className="Icon" alt="logo" />
                      <span className="botto-text">{data.mobile}</span>
                      <img src={Mail} className="Icon" alt="logo" />
                      <span className="botto-text">{data.email}</span>
                      <img src={loaction} className="Icon" alt="logo" />
                      <span className="botto-text">{data.city}</span>
                      <img src={account} className="Icon" alt="logo" />
                      <span className="botto-text">
                        Acceptance Date :{" "}
                        {data.agreementAcceptedDate.split(" ")[0]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <img src={nodatafound} className="nodatafound" alt="logo" />
      )}
    </div>
  );
};

export default SMAgreement;
