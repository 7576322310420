import { all } from "redux-saga/effects";
import assignmentsSaga from "./assignments.saga"
import assignmentDetailsSaga from "./assignmentDetails.saga"
import recentPanelistSaga from "./recentPanelist.saga";
import assignmentDetailsStatusSaga from "./assignmentDetailsStatus.saga"
import activePanelistSaga from "./activePanelist.saga";
import approveRejectPanelistSaga from "./approveRejctPanelist.saga";
import accountDataSaga from "./account.saga";
import rewardsSaga from "./rewards.saga";
import panelistDetails from "./panelist.saga";
import cureBayContentSaga from "./cureBayContent.saga"
import dashboardSaga from "./dashboard.saga"
import promotionListSaga from "./promotionList.saga"
import surgeryReferLeadsSaga from "./surgeryReferLeads.saga"
import activeOrInactivePanelist from "./activeOrInactivePanelist.saga"
import dataCollectionList from "./dataCollectionList.saga"
import eClinicList from "./eClinicList.saga"
import dataCollectionDetails from "./dataCollectionDetails.saga"



export default function* rootSaga() {
    yield all([
        assignmentsSaga(),
        assignmentDetailsSaga(),
        recentPanelistSaga(),
        assignmentDetailsStatusSaga(),
        activePanelistSaga(),
        approveRejectPanelistSaga(),
        accountDataSaga(),
        rewardsSaga(),
        panelistDetails(),
        cureBayContentSaga(),
        dashboardSaga(),
        promotionListSaga(),
        surgeryReferLeadsSaga(),
        activeOrInactivePanelist(),
        dataCollectionList(),
        eClinicList(),
        dataCollectionDetails()

    ]);
}
