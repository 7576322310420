import { combineReducers } from "redux";
import assignmentsReducer from "./assignments.reducer";
import assignmentDetails from "./assignmentDetails.reducer";
import panelistReducer from "./panelist.reducer";
import loginReducer from "./login.reducer";
import addAssignmentReducer from "./addAssignment.reducer";
import rewardsReducer from "./rewards.reducer";
import cureBayContentReducer from "./cureBayContent.reducer"
import dashboardReducers from "./dashboard.reducers/index.reducer";
import promotionListReducer from "./promotionList.reducer";
import curebayContentListReducer from "./curebayContentList.reducer";
import surgeryReferLeadsReducer from "./surgeryReferLeads.reducers/index.reducers.js";
import activeInActive from "./activeInActive.reducer";
import dataCollectionList from "./dataCollection.reducer";
import eClinicList from "./eClinicList.reducer";
import dataCollectionDetails from "./dataCollectionDetails.reducer";

export default combineReducers({
    assignments: assignmentsReducer,
    assignmentDetails: assignmentDetails,
    panelistData: panelistReducer,
    loginData: loginReducer,
    addAssignment: addAssignmentReducer,
    rewards: rewardsReducer,
    cureBayContent: cureBayContentReducer,
    dashboard: dashboardReducers,
    promotionList: promotionListReducer,
    cureBayContentList: curebayContentListReducer,
    surgeryReferLeads: surgeryReferLeadsReducer,
    activeInActive: activeInActive,
    dataCollectionList: dataCollectionList,
    eClinicList: eClinicList,
    dataCollectionDetails: dataCollectionDetails
});